import dayjs from "dayjs";
import { ReactElement, useEffect } from "react";
import { UserAccount } from "../../../api/backend";
import { ExpandableRowContext } from "../../../contexts";
import { CreateRows } from "./activityFn";
import ActivityView from "./activityView";
import { useActivityViewModel } from "./activityViewModel";

export type ActivityProps = {
    account: UserAccount;
    index: number;
};

export function ActivityModel(props: ActivityProps): ReactElement {
    const { context, state, api } = useActivityViewModel(props);

    useEffect(() => {
        state.setLoading(true);
        state.setSeeMore(false);
        api.transactionsAll()
            .then((res) => {
                state.setHistory(res.data);
                state.setActivityErr(false);
            })
            .catch(() => {
                state.setActivityErr(true);
            })
            .finally(() => {
                state.setLoading(false);
            });
    }, [props.index]);

    useEffect(() => {
        state.setYears(
            state.history
                .map((p) => dayjs(p.TransactionDate).year())
                .filter((p, i, s) => i === s.indexOf(p))
                .sort((a, b) => a - b)
        );
        setFilteredList();
    }, [state.history]);

    useEffect(() => {
        setFilteredList();
    }, [state.filter]);

    useEffect(() => {
        state.setDisplayedHistory(state.seeMore ? state.filteredHistory : state.filteredHistory.slice(0, 3));
    }, [state.filteredHistory, state.seeMore]);

    useEffect(() => {
        state.setRows(CreateRows(state, props.account));
    }, [state.displayedHistory]);

    function setFilteredList() {
        state.setFilteredHistory(
            state.history
                .filter((p, i, s) => {
                    if (state.filter > 0) return dayjs(p.TransactionDate).year() === state.filter;
                    else return true;
                })
                .sort((a, b) => dayjs(b.TransactionDate).diff(a.TransactionDate))
        );
    }

    function onViewMore() {
        state.setSeeMore(!state.seeMore);
    }

    return (
        <ExpandableRowContext.Provider value={{ ...context }}>
            <ActivityView {...state} onViewMore={onViewMore} />
        </ExpandableRowContext.Provider>
    );
}
