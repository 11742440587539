import { AxiosResponse } from "axios";
import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { IntermediateWarningCms, useCms } from "../../../api/backend";
import { useIsLoggedIn, useSessionStorage, useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { AppNavProps } from "../appNavProps";

type FooterViewModel = {
    hooks: FooterHooks;
    state: FooterState;
    api: FooterApi;
};

type FooterHooks = {
    navigate: NavigateFunction;
    storage: { [x: string]: any };
    storeValue: (name: string, value: any) => void;
};

export type FooterState = {
    size: Size;
    acProps: AppNavProps;
    setAcProps: React.Dispatch<React.SetStateAction<AppNavProps>>;
    iKey: string | undefined;
    setIKey: React.Dispatch<React.SetStateAction<string | undefined>>;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    modalCms: IntermediateWarningCms;
    setModalCms: React.Dispatch<React.SetStateAction<IntermediateWarningCms>>;
    modalDest: string;
    setModalDest: React.Dispatch<React.SetStateAction<string>>;
    loggedIn: boolean;
    openNewWindow: boolean;
    setOpenNewWindow: React.Dispatch<React.SetStateAction<boolean>>;
};

type FooterApi = {
    IntermediateWarning: () => Promise<AxiosResponse<IntermediateWarningCms, any>>;
};

const sessionKeys = ["intermediateWarning"];

export function useFooterViewModel(): FooterViewModel {
    const navigate = useNavigate();
    const { size } = useViewport(true);
    const [storage, storeValue] = useSessionStorage(sessionKeys);
    const [acProps, setAcProps] = useState({
        open: true,
        accord: "footer-",
        keys: ["0", "0", "0"]
    });
    const [iKey, setIKey] = useState("0" as string | undefined);
    const [showModal, setShowModal] = useState(false);
    const [modalCms, setModalCms] = useState({} as IntermediateWarningCms);
    const [modalDest, setModalDest] = useState("");
    const { IntermediateWarning } = useCms();
    const { loggedIn } = useIsLoggedIn();
    const [openNewWindow, setOpenNewWindow] = useState(false);

    return {
        hooks: { navigate, storage, storeValue },
        state: {
            size,
            acProps,
            setAcProps,
            iKey,
            setIKey,
            showModal,
            setShowModal,
            modalCms,
            setModalCms,
            modalDest,
            setModalDest,
            loggedIn,
            openNewWindow,
            setOpenNewWindow
        },
        api: {
            IntermediateWarning
        }
    };
}
