import { ReactElement, useEffect } from "react";
import { TransactionDetails, UserAccount } from "../../../../api/backend";
import { useActivityRowViewModel } from "./activityRowViewModel";
import { ActivityRowView } from "./activityRowView";

export type ActivityRowProps = {
    index: number;
    account: UserAccount;
    activity: TransactionDetails;
};

export function ActivityRow(props: ActivityRowProps): ReactElement {
    const { hooks, state } = useActivityRowViewModel(props);

    useEffect(() => {
        state.setExpanded(props.index === state.curIndex);
    }, [state.curIndex]);

    return <ActivityRowView {...props} {...state} newRowExpanded={hooks.newRowExpanded} />;
}
