import { useEffect, useState } from "react";
import { useSessionStorage } from ".";
import { CmsToolTip, ToolTipCode, ToolTipsCms, useCms } from "../api/backend";

export const useTooltipCms = () => {
    const { ToolTips, EmptyTip } = useCms();
    const [storage, storeValue] = useSessionStorage(["tooltips"]);
    const [tooltips, setTooltips] = useState(JSON.parse(storage["tooltips"] ?? "{}") as ToolTipsCms);

    useEffect(() => {
        if (storage["tooltips"] === undefined || storage["tooltips"] === ({} as ToolTipsCms)) {
            ToolTips().then((res) => {
                setTooltips(res.data);
                storeValue("tooltips", JSON.stringify(res.data));
            });
        } else {
            setTooltips(JSON.parse(storage["tooltips"]));
        }
    }, []);

    const getTooltip = (code: ToolTipCode): CmsToolTip => {
        return tooltips.ToolTips.find((t) => t.ToolTipCode === code) ?? EmptyTip("totalMinDueToolTip");
    };

    return { tooltips, getTooltip };
};
