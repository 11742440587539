import { ReactElement, useEffect } from "react";
import { MarketingSiteUrl, PremierCreditManagerUrl } from "../../../res/react-env-variables";
import FooterView from "./footerView";
import { useFooterViewModel } from "./footerViewModel";

export function FooterModel(): ReactElement {
    const { hooks, state, api } = useFooterViewModel();

    useEffect(() => {
        if (hooks.storage["intermediateWarning"] === undefined) {
            api.IntermediateWarning().then((res) => {
                state.setModalCms(res.data);
                hooks.storeValue("intermediateWarning", JSON.stringify(res.data));
            });
        } else {
            state.setModalCms(JSON.parse(hooks.storage["intermediateWarning"]));
        }
    }, []);

    useEffect(() => {
        if (state.size === "") {
            state.setAcProps({ open: true, accord: "footer-", keys: ["0", "0", "0"] });
            state.setIKey("0");
        } else {
            state.setAcProps({ open: false, accord: "", keys: ["1", "2", "3"] });
        }
    }, [state.size]);

    function onSelect(e: string | null) {
        state.setIKey(e?.toString());
    }

    function aboutUs() {
        window.location.href = `${MarketingSiteUrl}about`;
    }

    function contactUs() {
        window.location.href = `${MarketingSiteUrl}contact`;
    }

    function myPremierCreditCard() {
        window.location.href = `${MarketingSiteUrl}`;
    }

    function facebook() {
        state.setModalDest("https://www.facebook.com/PREMIERBankcard?mkt=306&submkt=4042");
        state.setShowModal(true);
    }

    function youtube() {
        state.setModalDest("https://www.youtube.com/channel/UCVDLImjOsrUqn_hcVFuO8lQ");
        state.setShowModal(true);
    }

    function instagram() {
        state.setModalDest("https://www.instagram.com/premierbankcard?mkt=306&submkt=4042");
        state.setShowModal(true);
    }

    function privacyPolicy() {
        hooks.navigate("/home/privacy");
    }

    function privacyNotice() {
        window.open(window.location.origin + "/home/privacy-notice", "_blank");
    }

    function termsAndConditions() {
        hooks.navigate("/home/terms-of-use");
    }

    function accessibilityStatement() {
        hooks.navigate("/home/accessibility");
    }

    function premierCreditCardAgreements() {
        hooks.navigate("/credit-card-agreements");
    }

    function ccpa() {
        hooks.navigate("/home/privacy#sec10");
    }

    function premierCreditProtectionContract() {
        window.open(
            `${MarketingSiteUrl}media/abvfi1to/premier_credit_protection_contract.pdf?mkt=306&submkt=4042`,
            "_blank"
        );
    }

    function premierCreditManager() {
        state.setModalDest(PremierCreditManagerUrl);
        state.setShowModal(true);
        state.setOpenNewWindow(true);
    }

    function cookieSettings(e: React.SyntheticEvent) {
        e.preventDefault();
        var otSdkBtnFloating = document.getElementById("ot-sdk-btn-floating");

        if (otSdkBtnFloating?.className !== "ot-floating-button ot-pc-open") {
            (window as any).Optanon.ToggleInfoDisplay();
        }
    }

    return (
        <FooterView
            {...state}
            onSelect={onSelect}
            aboutUs={aboutUs}
            contactUs={contactUs}
            myPremierCreditCard={myPremierCreditCard}
            facebook={facebook}
            youtube={youtube}
            instagram={instagram}
            privacyPolicy={privacyPolicy}
            privacyNotice={privacyNotice}
            termsAndConditions={termsAndConditions}
            accessibilityStatement={accessibilityStatement}
            premierCreditCardAgreements={premierCreditCardAgreements}
            ccpa={ccpa}
            premierCreditProtectionContract={premierCreditProtectionContract}
            cookieSettings={cookieSettings}
            premierCreditManager={premierCreditManager}
        />
    );
}
