import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { useContext, useEffect } from "react";
import { PaymentPostRequest, PaymentResponse } from ".";
import { useCms } from "..";
import { UseDelete, UseGet, UsePost } from "../../../hooks";
import { EqualsIgnoreCase } from "../../../utility";
import { useIdentityConnectContext } from "../../identityServer";
import { AccountContext } from "../accountClient/accountContext";
import { PaymentContext } from "./paymentContext";

export const usePaymentContext = () => {
    const context = useContext(PaymentContext);
    const aContext = useContext(AccountContext);
    const client = usePaymentClient();
    const { Payment } = useCms();

    useEffect(() => {
        Payment().then((res) => {
            context.setPaymentCms(res.data);
        });
    }, []);

    const makeNewPayment = (index: number) => {
        aContext.setAccountIndex(index);
        context.setShowMakePayment(true);
    };

    return { ...context, ...client, makeNewPayment };
};

const usePaymentClient = () => {
    const context = useContext(PaymentContext);
    const { access_token } = useIdentityConnectContext();

    async function Get(accountHandle: string): Promise<AxiosResponse<PaymentResponse[], any>> {
        context.setLoading(true);
        return await UseGet<PaymentResponse[]>(`api/v1/payment?accountHandle=${accountHandle}`, access_token).then(
            (x) => {
                let scheduled = x.data
                    .filter((p, i, s) => EqualsIgnoreCase(p.paymentStatus, "Scheduled"))
                    .sort((a, b) => dayjs(a.paymentDate).diff(b.paymentDate));
                let history = x.data.filter((p, i, s) => !EqualsIgnoreCase(p.paymentStatus, "Scheduled"));

                context.setScheduled(scheduled);
                context.setHistory(history);
                context.setLoading(false);
                return x;
            }
        );
    }

    async function Post(
        accountHandle: string,
        request: PaymentPostRequest
    ): Promise<AxiosResponse<PaymentResponse, any>> {
        return await UsePost<PaymentResponse, PaymentPostRequest>(`api/v1/payment`, access_token, request, {
            params: { accountHandle: accountHandle }
        }).then((x) => {
            Get(accountHandle);
            return x;
        });
    }

    async function Delete(accountHandle: string, paymentId: number): Promise<AxiosResponse<any, any>> {
        return await UseDelete(
            `api/v1/payment?accountHandle=${accountHandle}&paymentId=${paymentId}`,
            access_token
        ).then((x) => {
            Get(accountHandle);
            return x;
        });
    }

    return { Get, Post, Delete };
};
