import { AxiosError } from "axios";
import { FormikHelpers } from "formik";
import { ReactElement, useEffect } from "react";
import { ValidatePassword } from "../..";
import { useSettingsContext } from "../../../api/backend";
import { IsNullOrEmpty } from "../../../utility";
import { ChangePasswordFields } from "../../profile/changePassword/changePasswordView";
import { NewPasswordView } from "./newPasswordView";
import { useNewPasswordViewModel } from "./newPasswordViewModel";

export function NewPasswordModel(): ReactElement {
    const { hooks, state, api } = useNewPasswordViewModel();
    const { appSettings } = useSettingsContext();
    const isEMessengerDown: boolean = appSettings?.featureFlags?.isEMessengerDown;

    useEffect(() => {
        if (state.showSplash)
            setTimeout(() => {
                state.setSecurityType("Login");
                hooks.navigate(
                    IsNullOrEmpty(state.deepLink) || state.deepLink === "/" ? "/account/dashboard" : state.deepLink
                );
            }, 3000);
    }, [state.showSplash]);

    function checkPassword(password: string, passwordType: string, values: ChangePasswordFields): void {
        let newPassword = passwordType === "password" ? password : values.password;
        let confirmPassword = passwordType === "confirmPassword" ? password : values.confirmPassword;
        let verifyPassword = passwordType === "verifyPassword" ? password : values.verifyPassword;

        let confirmed = newPassword === confirmPassword && confirmPassword.length > 0;

        values.passwordCriteria = ValidatePassword(newPassword, confirmPassword, state.passwordTouched);

        values.passwordCriteria = {
            ...values.passwordCriteria,
            confirmed: confirmed
        };

        if (!state.passwordTouched && newPassword.length > 0) state.setPasswordTouched(true);
        if (!state.confirmTouched && confirmPassword.length > 0) state.setConfirmTouched(true);

        state.setCanContinue(
            newPassword.length > 0 &&
                confirmPassword.length > 0 &&
                values.passwordCriteria.hasError === false &&
                (state.securityType !== "TempPassword" || verifyPassword.length > 0)
        );
    }

    const onCancel = () => {
        state.setShowModal(true);
    };

    const onSubmit = (values: ChangePasswordFields, actions: FormikHelpers<ChangePasswordFields>) => {
        let currentPassword = "";
        let recovery = true;

        if (state.securityType === "TempPassword") {
            currentPassword = values.verifyPassword;
            recovery = false;
        }

        api.changePassword({ CurrentPassword: currentPassword, DesiredPassword: values.password, Recovery: recovery })
            .then((res) => {
                hooks.removeLocal("TrustedDeviceAuthentication");
                api.userAccounts()
                    .then(async (res2) => {
                        await api
                            .digitalActions(res2.data)
                            .then(async (daRes) => {
                                if (!isEMessengerDown) {
                                    await api.getPreferences().catch((err) => {
                                        console.log(err);
                                    });
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });

                        if (res2.data.length > 0) {
                            hooks.initializeSwrve(res2.data[0].applicantHandle);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        state.setShowSplash(true);
                        actions.setSubmitting(false);
                    });
            })
            .catch((err: AxiosError<any, any>) => {
                if (err.response?.data?.errors?.CurrentPassword && err.response?.data?.errors?.DesiredPassword) {
                    actions.setErrors({
                        verifyPassword: "Temporary Password Invalid",
                        password: err.response.data.errors.DesiredPassword[0]
                    });
                } else if (err.response?.data?.errors?.CurrentPassword) {
                    actions.setErrors({
                        verifyPassword: "Temporary Password Invalid"
                    });
                } else if (err.response?.data?.errors?.DesiredPassword) {
                    actions.setErrors({
                        password: err.response.data.errors.DesiredPassword[0]
                    });
                }

                actions.setSubmitting(false);
            });
    };

    return <NewPasswordView {...state} checkPassword={checkPassword} onCancel={onCancel} onSubmit={onSubmit} />;
}
