export type ElectronicDeliveryPreferences = {
    accountHandle: string;
    downForMaintenance: boolean;
    eStatementsMaintenanceMessage: string;
    deliveryPreferencesMaintenanceMessage: string;
    showCycleDateMessage: boolean;
    emailAddress: string;
    eStatementsEnrollment: ElectronicDeliveryPreference;
    eLettersEnrollment: ElectronicDeliveryPreference;
    changed?: boolean;
};

export type ElectronicDeliveryPreference = {
    enrollmentStatus: string;
    changesAllowed: boolean;
};

export type EnrollRequest = {
    accountHandle: string;
    enrollStatements: boolean | undefined;
    enrollDocuments: boolean | undefined;
    allAccounts: boolean | undefined;
};

export type UnenrollRequest = {
    accountHandle: string;
    unenrollStatements: boolean | undefined;
    unenrollDocuments: boolean | undefined;
    allAccounts: boolean | undefined;
};

export function appendOptionalParam(query: string, name: string, value: any | undefined) {
    return value !== undefined ? query + `&${name}=${value}` : query;
}
