import dayjs from "dayjs";
import { ReactElement, useEffect } from "react";
import { ExpandableRowContext } from "../../../contexts";
import { CreateRows } from "./activityFn";
import { ActivityProps } from "./activityModel";
import ActivityRecentView from "./activityRecentView";
import { useActivityViewModel } from "./activityViewModel";

export function ActivityRecentModel(props: ActivityProps): ReactElement {
    const { context, hooks, state, api } = useActivityViewModel(props);

    useEffect(() => {
        state.setLoading(true);

        api.transactionsRecent()
            .then((res) => {
                state.setHistory(res.data.sort((a, b) => dayjs(b.TransactionDate).diff(a.TransactionDate)));
            })
            .finally(() => {
                state.setLoading(false);
            });
    }, [props.index]);

    useEffect(() => {
        state.setDisplayedHistory(state.history);
    }, [state.history]);

    useEffect(() => {
        state.setRows(CreateRows(state, props.account));
    }, [state.displayedHistory]);

    function onViewAll() {
        const url = "/account/summary/" + props.index + "/activity";
        hooks.navigate(url);
    }

    return (
        <ExpandableRowContext.Provider value={{ ...context }}>
            <ActivityRecentView {...state} onViewAll={onViewAll} />
        </ExpandableRowContext.Provider>
    );
}
