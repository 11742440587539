import { useThemeSwitcher } from "../../../hooks/useThemeSwitcher";
import { TogglePro } from "../../generic";
import "./palletTest.scss";

export function PalletTest(): React.ReactElement {
    const { theme, setTheme } = useThemeSwitcher();

    function onToggled() {
        if (theme === "light") {
            setTheme("dark");
        } else {
            setTheme("light");
        }
    }

    return (
        <div className="palletTest">
            <TogglePro onToggle={() => onToggled()} />

            <div className="palletTest-light">
                <div className="palletTest-swatch">
                    <p className="palletTest-lable-dark">GS</p>
                    <div className="palletTest-swatch-blk" />
                    <div className="palletTest-swatch-drk" />
                    <div className="palletTest-swatch-med" />
                    <div className="palletTest-swatch-lit" />
                    <div className="palletTest-swatch-wht" />
                </div>
                <div className="palletTest-swatch">
                    <p className="palletTest-lable-dark">T</p>
                    <div className="palletTest-swatch-tp" />
                </div>
                <div className="palletTest-swatch">
                    <p className="palletTest-lable-dark">P</p>
                    <div className="palletTest-swatch-pd" />
                    <div className="palletTest-swatch-pm" />
                    <div className="palletTest-swatch-pl" />
                </div>

                <div className="palletTest-swatch">
                    <p className="palletTest-lable-dark">S</p>
                    <div className="palletTest-swatch-sd" />
                    <div className="palletTest-swatch-sm" />
                    <div className="palletTest-swatch-sl" />
                </div>

                <div className="palletTest-swatch">
                    <p className="palletTest-lable-dark">A</p>
                    <div className="palletTest-swatch-a1" />
                    <div className="palletTest-swatch-a2" />
                    <div className="palletTest-swatch-a3" />
                </div>
                <div className="palletTest-swatch">
                    <p className="palletTest-lable-dark">O</p>
                    <div className="palletTest-swatch-w" />
                    <div className="palletTest-swatch-e" />
                    <div className="palletTest-swatch-el" />
                </div>
                <div className="palletTest-swatch">
                    <p className="palletTest-lable-dark">B</p>
                    <div className="palletTest-swatch-o" />
                    <div className="palletTest-swatch-i" />
                    <div className="palletTest-swatch-w" />
                    <div className="palletTest-swatch-pm" />
                    <div className="palletTest-swatch-a1" />
                </div>
            </div>
        </div>
    );
}
