import dayjs from "dayjs";
import { ReactElement, useEffect } from "react";
import { UserAccount, useSettingsContext } from "../../../api/backend";
import { ExpandableRowContext } from "../../../contexts";
import { StatementsRow } from "./statementsRow/statementsRow";
import StatementsView from "./statementsView";
import { useStatementsViewModel } from "./statementsViewModel";

export type StatementsProps = {
    account: UserAccount;
    index: number;
};

export function StatementsModel(props: StatementsProps): ReactElement {
    const { context, state, api } = useStatementsViewModel(props);
    const { appSettings } = useSettingsContext();
    const isEMessengerDown: boolean = appSettings?.featureFlags?.isEMessengerDown;

    useEffect(() => {
        if (!isEMessengerDown) {
            state.setLoading(true);

            api.statementsGet()
                .then((res) => {
                    state.setHistory(res.data.sort((a, b) => dayjs(b.statementDate).diff(a.statementDate)));
                })
                .finally(() => {
                    state.setLoading(false);
                });
        }
    }, [props.index]);

    useEffect(() => {
        state.setRows(createRows());
    }, [state.history]);

    function createRows(): ReactElement[] {
        let newRows = [] as ReactElement[];

        for (let i = 0; i < state.history.length; i++) {
            newRows.push(
                <StatementsRow
                    key={`history[i].statementId.toString()${i}`}
                    index={i}
                    account={props.account}
                    statement={state.history[i]}
                />
            );
        }

        return newRows;
    }

    return (
        <ExpandableRowContext.Provider value={{ ...context }}>
            <StatementsView {...state} />
        </ExpandableRowContext.Provider>
    );
}
