import { CreditLineIncreaseCms, useCms } from "../../../api/backend";

export type RequestCLIData = {
    cms: CreditLineIncreaseCms;
};

export async function RequestCLILoader(): Promise<RequestCLIData> {
    const { CreditLineIncrease } = useCms();
    const cms = (await CreditLineIncrease()).data;

    return { cms };
}
