import { ReactElement } from "react";
import { useSettingsContext } from "../../../api/backend";
import { AsyncElement } from "../../generic";
import "./statements.scss";
import { StatementsState } from "./statementsViewModel";

function StatementsView(props: StatementsState): ReactElement {
    const { appSettings } = useSettingsContext();
    const showStatements: boolean = !appSettings?.featureFlags?.isEMessengerDown;
    const noRowsMessage: string = showStatements
        ? "No statements available"
        : "Viewing statements is currently unavailable due to site maintenance, which may take up to 7 hours. Please check back later.";

    return (
        <div id="statements">
            <div className="statements-header">
                <h5>Statements</h5>
            </div>
            {showStatements ? (
                <AsyncElement loading={props.loading}>
                    <div className="statements-list">
                        {props.rows.length > 0 ? (
                            props.rows
                        ) : (
                            <div className="statements-noList">
                                <h5>{noRowsMessage}</h5>
                            </div>
                        )}
                    </div>
                </AsyncElement>
            ) : (
                <div className="statements-list">
                    <div className="statements-noList">
                        <h5>{noRowsMessage}</h5>
                    </div>
                </div>
            )}

            <div className="statements-footer">
                <p>
                    {props.rows.length} result{props.rows.length !== 1 ? "s" : ""}
                </p>
            </div>
        </div>
    );
}

export default StatementsView;
