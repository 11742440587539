import { AxiosResponse } from "axios";
import { ReactElement, useState } from "react";
import { StatementDetails, useStatementsContext } from "../../../api/backend";
import { StatementsProps } from "./statementsModel";

type StatementsViewModel = {
    context: StatementsContext;
    state: StatementsState;
    api: StatementsApi;
};

type StatementsContext = {
    curIndex: number | undefined;
    setCurIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
};

export type StatementsState = {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    history: StatementDetails[];
    setHistory: React.Dispatch<React.SetStateAction<StatementDetails[]>>;
    rows: ReactElement[];
    setRows: React.Dispatch<React.SetStateAction<ReactElement[]>>;
};

export type StatementsApi = {
    statementsGet: () => Promise<AxiosResponse<StatementDetails[], any>>;
};

export function useStatementsViewModel(props: StatementsProps): StatementsViewModel {
    const { Get } = useStatementsContext();
    const [history, setHistory] = useState([] as StatementDetails[]);
    const [rows, setRows] = useState([] as ReactElement[]);
    const [curIndex, setCurIndex] = useState(undefined as undefined | number);
    const [loading, setLoading] = useState(false);

    async function statementsGet(): Promise<AxiosResponse<StatementDetails[], any>> {
        return Get(props.account.accountHandle);
    }

    return {
        context: {
            curIndex,
            setCurIndex
        },
        state: {
            loading,
            setLoading,
            history,
            setHistory,
            rows,
            setRows
        },
        api: {
            statementsGet
        }
    };
}
