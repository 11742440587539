import { useEffect, useRef, useState } from "react";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import {
    AppSettings,
    OnlineActions,
    PremierCreditProtectionCMS,
    PremierCreditProtectionResponse,
    UserAccount,
    useAccountContext,
    useCms,
    useContextualMessagingContext,
    useDigitalActionsContext,
    usePCPContext,
    useSettingsContext
} from "../../../../api/backend";
import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";
import { GenericModalProps } from "../../../generic";
import { AccountService } from "./navMenu";

type NavMenuViewModel = {
    hooks: NavMenuHooks;
    state: NavMenuState;
};

type NavMenuHooks = {
    navigate: NavigateFunction;
    getOrder: (option: string) => number;
    getTitle: (option: string) => string;
    isService: (option: string) => boolean;
    actionAllowed: (option: string, accountHandle?: string | undefined) => boolean;
};

export type NavMenuState = {
    size: Size;
    location: Location<any>;
    appSettings: AppSettings;
    accounts: UserAccount[] | undefined;
    account: UserAccount;
    accountIndex: number;
    setAccountIndex: React.Dispatch<React.SetStateAction<number>>;
    lockStatus: boolean;
    services: React.MutableRefObject<AccountService[]>;
    digitalActions: OnlineActions[] | undefined;
    cardNumberCN: string;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    showDocUplPrimeModal: boolean;
    setShowDocUplPrimeModal: React.Dispatch<React.SetStateAction<boolean>>;
    showLockCardDisabledModal: boolean;
    setShowLockCardDisabledModal: React.Dispatch<React.SetStateAction<boolean>>;
    showLockCardDisabledFromStatusModal: boolean;
    setShowLockCardDisabledFromStatusModal: React.Dispatch<React.SetStateAction<boolean>>;
    showManageAlertsDisabledModal: boolean;
    setShowManageAlertsDisabledModal: React.Dispatch<React.SetStateAction<boolean>>;
    showManageAlertsUnavailableModal: boolean;
    setShowManageAlertsUnavailableModal: React.Dispatch<React.SetStateAction<boolean>>;
    premierCreditProtectionEnrollment: PremierCreditProtectionResponse | undefined;
    setPremierCreditProtectionEnrollment: React.Dispatch<
        React.SetStateAction<PremierCreditProtectionResponse | undefined>
    >;
    PCPUnavailable: GenericModalProps;
    setPCPUnavailable: React.Dispatch<React.SetStateAction<GenericModalProps>>;
    pcpCMS: PremierCreditProtectionCMS | undefined;
    setpcpCMS: React.Dispatch<React.SetStateAction<PremierCreditProtectionCMS | undefined>>;
    showPCPUnavailableModal: boolean;
    setShowPCPUnavailableModal: React.Dispatch<React.SetStateAction<boolean>>;
    paymentActions: boolean | undefined;
};

export function useNavMenuViewModel(): NavMenuViewModel {
    const { size } = useViewport(false, true);
    const location = useLocation();
    const navigate = useNavigate();
    const { appSettings } = useSettingsContext();
    const { ControlsStatus } = useContextualMessagingContext();
    const { Get: PCPEnrollmentStatus } = usePCPContext();
    const { PremierCreditProtection } = useCms();
    const services = useRef([] as AccountService[]);
    const { digitalActions, getOrder, getTitle, isService, actionAllowed } = useDigitalActionsContext();
    const { accounts, accountIndex, setAccountIndex } = useAccountContext();
    let account = accounts![accountIndex];
    let im = account.accent;
    let cardNumberCN = `cardNav-cardNumber-${im}`;
    const [loading, setLoading] = useState(false);
    const [lockStatus, setLockStatus] = useState(false);
    const [showDocUplPrimeModal, setShowDocUplPrimeModal] = useState(false);
    const [showLockCardDisabledModal, setShowLockCardDisabledModal] = useState(false);
    const [showLockCardDisabledFromStatusModal, setShowLockCardDisabledFromStatusModal] = useState(false);
    const [showManageAlertsDisabledModal, setShowManageAlertsDisabledModal] = useState(false);
    const [showManageAlertsUnavailableModal, setShowManageAlertsUnavailableModal] = useState(false);
    const [pcpCMS, setpcpCMS] = useState<PremierCreditProtectionCMS>();
    const [premierCreditProtectionEnrollment, setPremierCreditProtectionEnrollment] =
        useState<PremierCreditProtectionResponse>();
    const [showPCPUnavailableModal, setShowPCPUnavailableModal] = useState(false);
    const [paymentActions, setPaymentActions] = useState<boolean | undefined>(undefined);
    const [PCPUnavailable, setPCPUnavailable] = useState<GenericModalProps>({
        headerText: "PREMIER Credit Protection Not Available",
        bodyText: ``,
        primaryButton: {
            text: "Close",
            action: () => {
                setShowPCPUnavailableModal(false);
            }
        }
    });

    useEffect(() => {
        if (!premierCreditProtectionEnrollment) {
            setLoading(true);
            PCPEnrollmentStatus(accounts![0].applicantHandle)
                .then((res) => {
                    setPremierCreditProtectionEnrollment(res.data);
                    PremierCreditProtection()
                        .then((res) => {
                            setpcpCMS(res.data);
                            let placeholder = { ...PCPUnavailable };
                            if (res.data?.IneligibleText) {
                                placeholder.bodyText = res.data.IneligibleText;

                            }
                            setPCPUnavailable(placeholder);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    useEffect(() => {
        if (appSettings?.featureFlags?.lockCardEnabled) {
            ControlsStatus(account.accountHandle).then((res) => {
                setLockStatus(res.data.isLocked);
            });
        } else {
            setLockStatus(false);
        }
    }, []);

    useEffect(() => {
        setPaymentActions(actionAllowed("payments", accounts![accountIndex].accountHandle));
    }, [accountIndex]);

    return {
        hooks: {
            navigate,
            getOrder,
            getTitle,
            isService,
            actionAllowed
        },
        state: {
            size,
            location,
            appSettings,
            accounts,
            account,
            accountIndex,
            setAccountIndex,
            lockStatus,
            services,
            digitalActions,
            cardNumberCN,
            loading,
            setLoading,
            showDocUplPrimeModal,
            setShowDocUplPrimeModal,
            showLockCardDisabledModal,
            setShowLockCardDisabledModal,
            showLockCardDisabledFromStatusModal,
            setShowLockCardDisabledFromStatusModal,
            showManageAlertsDisabledModal,
            setShowManageAlertsDisabledModal,
            showManageAlertsUnavailableModal,
            setShowManageAlertsUnavailableModal,
            premierCreditProtectionEnrollment,
            setPremierCreditProtectionEnrollment,
            PCPUnavailable,
            setPCPUnavailable,
            pcpCMS,
            setpcpCMS,
            setShowPCPUnavailableModal,
            showPCPUnavailableModal,
            paymentActions
        }
    };
}
