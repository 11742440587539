import {
    CreditCardAgreement,
    CurrentCreditCardOffersCms,
    useCms,
    useCreditCardAgreementsContext
} from "../../../api/backend";

export type CreditCardAgreementData = {
    cms: CurrentCreditCardOffersCms;
    links: CreditCardAgreement[];
};

export async function CreditCardAgreementLoader(): Promise<CreditCardAgreementData> {
    const { Get } = useCreditCardAgreementsContext();
    const { CurrentCreditCardOffers } = useCms();

    const links = (await Get()).data;
    const cms = (await CurrentCreditCardOffers()).data;

    return { cms, links };
}
