import dayjs from "dayjs";
import { Field, useFormikContext } from "formik";
import React, { ReactElement, useEffect, useState } from "react";
import { useActiveElement } from "../../../hooks";
import { FormProps } from "../../../types";
import { IsNullOrEmpty } from "../../../utility";
import { ButtonPro } from "../../generic/inputs/buttonPro/buttonPro";
import "./inputField.scss";
import InputMask from "./inputMask";
import { defaultFormatChars, privateFormatChars } from "./inputMask/constants";

//This can only be used in a Formik Form!!
export function InputFieldView(props: {
    id: string;
    label?: string;
    placeholder?: string;
    children?: ReactElement;
    error?: string;
    type?: string;
    maxLength?: number;
    mask?: string;
    formatChars?: any;
    onChange?: Function;
    onBlur?: Function;
    onPaste?: Function;
    isPrivate: boolean;
    canPeek: boolean;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    handleKeyPress?: Function;
    inputMode?: string;
    pattern?: string;
    ignoreTouched: boolean;
    touched: boolean;
    alwaysHighlightLine?: boolean;
    showErrorMessage?: boolean;
    highLightLineClass?: string;
    autoComplete?: string;
}): ReactElement {
    const active = useActiveElement();
    const [cn, setCn] = useState("inputField-row");
    const formik = useFormikContext<any>();
    const bag: FormProps<any> = { ...formik };
    const showErrorMessageText = props.showErrorMessage === undefined ? true : props.showErrorMessage;
    const error = props.error === undefined ? false : props.error;
    const highLightLineClassString =
        IsNullOrEmpty(props.highLightLineClass) || props.highLightLineClass === undefined
            ? ""
            : props.highLightLineClass;

    useEffect(() => {
        setCn(
            props.alwaysHighlightLine && highLightLineClassString !== ""
                ? highLightLineClassString
                : active?.id === props.id && error && props.alwaysHighlightLine
                  ? "inputField-row-error"
                  : active?.id === props.id
                    ? "inputField-row-active"
                    : !error && props.alwaysHighlightLine && props.touched
                      ? "inputField-row-active"
                      : !props.error
                        ? "inputField-row"
                        : props.error && props.touched
                          ? "inputField-row-error"
                          : error && props.alwaysHighlightLine
                            ? "inputField-row-error"
                            : "inputField-row"
        );
    }, [active, props.error, props.touched]);

    const formatChars = props.formatChars
        ? props.formatChars
        : props.isPrivate
          ? privateFormatChars
          : defaultFormatChars;

    return (
        <div className="inputField">
            {props.children ? (
                <div className="inputField-children">
                    <label htmlFor={props.id}>{props.label}</label>
                    {props.children}
                </div>
            ) : (
                <label htmlFor={props.id}>{props.label}</label>
            )}
            <div className={cn}>
                <Field name={props.id}>
                    {({ field }: any) => (
                        <InputMask
                            {...field}
                            id={props.id}
                            type={props.type}
                            maxLength={props.maxLength}
                            className={
                                !props.isPrivate
                                    ? "inputField-inputValue"
                                    : props.show
                                      ? "inputField-inputValue-show"
                                      : "inputField-inputValue-hide"
                            }
                            placeholder={props.placeholder}
                            mask={props.mask}
                            formatChars={formatChars}
                            onKeyPress={props.handleKeyPress}
                            autoComplete={!IsNullOrEmpty(props.autoComplete) ? props.autoComplete : "off"}
                            inputMode={props.inputMode}
                            pattern={props.pattern}
                            onBlur={(e: { currentTarget: { value: string; type: string } }) => {
                                if (e.currentTarget.value === "") e.currentTarget.type = "text";
                                bag.touched[props.id] = true;
                                if (props.onBlur) props.onBlur(bag);
                            }}
                            onPaste={(e: React.ClipboardEvent) => {
                                if (props.onPaste) props.onPaste(e);
                            }}
                        />
                    )}
                </Field>
                {props.canPeek ? (
                    <ButtonPro
                        tabIndex={-1}
                        className="inputField-show"
                        variant="neutral"
                        onClick={() => {
                            props.setShow(!props.show);
                        }}
                        unblock
                    >
                        <h6>{props.show ? "Hide" : "Show"}</h6>
                    </ButtonPro>
                ) : (
                    <></>
                )}
            </div>

            {showErrorMessageText && (props.touched || props.ignoreTouched) && props.error && (
                <div className="validation-error text-start">
                    <h6>{props.error}</h6>
                </div>
            )}
        </div>
    );
}

export function InputDateFieldView(props: {
    id: string;
    label?: string;
    placeholder?: string;
    children?: ReactElement;
    error?: string;
    mask?: string;
    onChange?: Function;
    onBlur?: Function;
    handleKeyPress?: Function;
}): ReactElement {
    const formik = useFormikContext<any>();
    const bag: FormProps<any> = { ...formik };
    const max = dayjs.tz().format("YYYY-MM-DD");
    const min = dayjs.tz("1901-01-01").format("YYYY-MM-DD");
    const active = useActiveElement();
    const [cn, setCn] = useState("inputField-row");

    useEffect(() => {
        setCn(
            active?.id === props.id
                ? "inputField-row-active"
                : !props.error
                  ? "inputField-row"
                  : props.error && formik.touched[props.id]
                    ? "inputField-row-error"
                    : "inputField-row"
        );
    }, [active, props.error, formik.touched[props.id]]);

    useEffect(() => {
        formik.touched[props.id] = false;
        formik.errors[props.id] = "";
    }, []);

    return (
        <div className="inputField">
            {props.children ? (
                <div className="inputField-children">
                    <label htmlFor={props.id}>{props.label}</label>
                    {props.children}
                </div>
            ) : (
                <label htmlFor={props.id}>{props.label}</label>
            )}
            <div className={cn}>
                <Field name={props.id}>
                    {({ field }: any) => (
                        <input
                            {...field}
                            id={props.id}
                            type="text"
                            max={max}
                            min={min}
                            autoComplete={"off"}
                            placeholder={"MM/DD/YYYY"}
                            onFocus={(e) => {
                                e.currentTarget.type = "date";
                            }}
                            onBlur={(e) => {
                                if (e.currentTarget.value === "") e.currentTarget.type = "text";
                                bag.touched[props.id] = true;
                                if (props.onBlur) props.onBlur(bag);
                            }}
                            className={"inputField-inputValue"}
                        />
                    )}
                </Field>
            </div>
            {props.error && (
                <div className="validation-error text-start">
                    <h6>{props.error}</h6>
                </div>
            )}
        </div>
    );
}
