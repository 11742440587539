import { AxiosResponse } from "axios";
import { CreditCardAgreement } from ".";
import { UseGet } from "../../../hooks";

export const useCreditCardAgreementsContext = () => {
    const client = useCreditCardAgreementsClient();

    return { ...client };
};

const useCreditCardAgreementsClient = () => {
    async function Get(): Promise<AxiosResponse<CreditCardAgreement[], any>> {
        return await UseGet("api/v1/creditcardagreements");
    }

    return { Get };
};
