import { AxiosError } from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useIdentityConnectContext } from "../api/identityServer";

export const useUnhandledPromise = (): void => {
    const navigate = useNavigate();
    const { clear } = useIdentityConnectContext();

    const handlePromise = (event: PromiseRejectionEvent) => {
        if (event.reason instanceof AxiosError) {
            if (event.reason.response?.status) {
                switch (event.reason.response?.status) {
                    case 401: {
                        clear("access");
                        navigate("/auth/login");
                        break;
                    }
                    case 403: {
                        clear("access");
                        navigate("/Error403");
                        break;
                    }
                    case 500: {
                        navigate("/Error500");
                        break;
                    }
                }

                return;
            }

            if (event.reason.code?.toUpperCase().includes("ERR_")) {
                navigate("/Error500");
            }
        }
    };

    useEffect(() => {
        window.addEventListener("unhandledrejection", handlePromise);
        return () => window.removeEventListener("unhandledrejection", handlePromise);
    }, []);
};
