import { ReactElement } from "react";
import { ButtonPro } from "../../generic";
import { Exit } from "../../generic/icons";
import "./intermediateWarning.scss";
import { IntermediateWarningState } from "./intermediateWarningViewModel";

type IntermediateWarningViewProps = IntermediateWarningState & {
    onLeave: Function;
    onStay: Function;
};

function IntermediateWarningView(props: IntermediateWarningViewProps): ReactElement {
    return (
        <div id="intermediateWarning">
            <div id="intermediateWarning-header">
                <h4>{props.headerText}</h4>
                <Exit className="intermediateWarning-exit" onClick={() => props.onStay()} />
            </div>
            <div id="intermediateWarning-content">
                <span dangerouslySetInnerHTML={{ __html: props.bodyText }} />
            </div>
            <div id="intermediateWarning-buttons">
                <ButtonPro
                    id="intermediateWarning-stay"
                    content={"Stay on Page"}
                    variant="neutral"
                    onClick={() => props.onStay()}
                />
                <ButtonPro content={"Leave Page"} onClick={() => props.onLeave()} />
            </div>
        </div>
    );
}

export default IntermediateWarningView;
