import { ReactElement } from "react";
import { Dropdown } from "react-bootstrap";
import { ButtonPro } from "../../../generic";
import { ArrowDown, Check } from "../../../generic/icons";
import "./activityDateFilter.scss";

type ActivityDateFilterProps = {
    years: number[];
    filter: number;
    setFilter: React.Dispatch<React.SetStateAction<number>>;
};

export function ActivityDateFilter(props: ActivityDateFilterProps): ReactElement {
    const sixMonth = (
        <Dropdown.Item key="0" eventKey={0} className="activityDateFilter-item" onClick={() => props.setFilter(0)}>
            <p>Last 6 months</p>
            {props.filter === 0 ? <Check /> : <></>}
        </Dropdown.Item>
    );

    const items = [sixMonth] as ReactElement[];
    for (let i = props.years.length; i > 0; i--) {
        items.push(
            <Dropdown.Item
                key={props.years[i - 1]}
                eventKey={i}
                className="activityDateFilter-item"
                onClick={() => {
                    props.setFilter(props.years[i - 1]);
                }}
            >
                <p>{props.years[i - 1]}</p>
                {props.filter === props.years[i - 1] ? <Check /> : <></>}
            </Dropdown.Item>
        );
    }

    return (
        <Dropdown align="end" className="activityDateFilter">
            <Dropdown.Toggle as="div" className="activityDateFilter-toggle">
                <ButtonPro variant="neutral" className="activityDateFilter-button">
                    <p>{props.filter === 0 ? "Last 6 months" : props.filter}</p>
                    <ArrowDown />
                </ButtonPro>
            </Dropdown.Toggle>
            <Dropdown.Menu className="activityDateFilter-menu">{items}</Dropdown.Menu>
        </Dropdown>
    );
}
