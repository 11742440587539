import { useLayoutEffect, useMemo, useState } from "react";

export function useLocalStorage<T extends string, U = { [K in T]?: any }>(
    dependencies?: T[]
): [U, (name: T, value: any) => void, (name: T) => void] {
    const [storage, setItems] = useState(getAll());

    useLayoutEffect(() => {
        function onChange() {
            const newItems = getAll();

            if (shouldUpdate(dependencies || null, newItems, storage)) {
                setItems(newItems);
            }
        }

        if (isInBrowser()) {
            window.addEventListener("localStorage", onChange);

            return () => {
                window.removeEventListener("localStorage", onChange);
            };
        }
    }, [storage]);

    const storeValue = useMemo(() => setItemFn, [storage]);
    const removeValue = useMemo(() => removeItemFn, [storage]);

    return [storage, storeValue, removeValue];
}

const getAll = (): any => {
    const result: { [name: string]: any } = {};

    const keys = Object.keys(localStorage);
    let i = keys.length;

    while (i--) {
        result[keys[i]] = localStorage.getItem(keys[i]);
    }

    return result;
};

function isInBrowser() {
    return (
        typeof window !== "undefined" &&
        typeof window.document !== "undefined" &&
        typeof window.document.createElement !== "undefined"
    );
}

function shouldUpdate<U = { [K: string]: any }>(dependencies: Array<keyof U> | null, newItems: U, oldItems: U) {
    if (!dependencies) {
        return true;
    }

    for (let dependency of dependencies) {
        if (newItems[dependency] !== oldItems[dependency]) {
            return true;
        }
    }

    return false;
}

const setItemFn = <T extends string>(name: T, value: any): void => {
    window.dispatchEvent(new Event("localStorage"));
    localStorage.setItem(name, value);
};

const removeItemFn = <T extends string>(name: T): void => {
    window.dispatchEvent(new Event("localStorage"));
    localStorage.removeItem(name);
};
