import { PublicCCPAPageConfiguration, useCms } from "../../../../api/backend";

export type CCPAHomeData = {
    cms: PublicCCPAPageConfiguration;
};

export async function CCPAHomeLoader(): Promise<CCPAHomeData> {
    const { CCPAPublicPage } = useCms();
    const cms = (await CCPAPublicPage()).data.PublicCCPAPageConfiguration;

    return { cms };
}
