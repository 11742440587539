import { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom";
import {
    ApplicationStatusCms,
    MultipleAccountPopupResponse,
    UserAccount,
    useAccountContext,
    useCms,
    useDeliveryPreferencesContext,
    useDigitalActionsContext
} from "../../../api/backend";
import { CardNavContext } from "../../../contexts";
import { useSessionStorage, useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { IsNullOrEmpty } from "../../../utility";

type CardSummaryViewModel = {
    hooks: CardSummaryHooks;
    state: CardSummaryState;
    api: CardSummaryApi;
};

type CardSummaryHooks = {
    navigate: NavigateFunction;
    storage: { [x: string]: any };
    storeValue: (name: string, value: any) => void;
    actionAllowed: (option: string, accountHandle?: string | undefined) => boolean;
};

export type CardSummaryState = {
    size: Size;
    urlId: string | undefined;
    accounts: UserAccount[] | undefined;
    accountIndex: number;
    setAccountIndex: React.Dispatch<React.SetStateAction<number>>;
    setShowNav: React.Dispatch<React.SetStateAction<boolean>>;
    offerAccountHandle: string | undefined;
    showOfferPopup: boolean;
    setShowOfferPopup: React.Dispatch<React.SetStateAction<boolean>>;
    bodyText: string;
    setBodyText: React.Dispatch<React.SetStateAction<string>>;
    redirectUrl: string;
    setRedirectUrl: React.Dispatch<React.SetStateAction<string>>;
    setPopupId: React.Dispatch<React.SetStateAction<number>>;
    apiFailedToPost: number;
    setApiFailedToPost: React.Dispatch<React.SetStateAction<number>>;
    showUnLockCardSuccess: boolean;
    showPreferencesLanding: boolean;
    appCms: ApplicationStatusCms | undefined;
};

type CardSummaryApi = {
    maPopup: () => Promise<AxiosResponse<MultipleAccountPopupResponse, any>>;
    maPopupDisplayed: (id: number) => Promise<AxiosResponse<MultipleAccountPopupResponse, any>>;
};

const storageKeys = ["ShowMultAcctOfferPopup"];

export function useCardSummaryViewModel(): CardSummaryViewModel {
    const location = useLocation();
    const navigate = useNavigate();
    const { size } = useViewport(true, true);
    const { id } = useParams();
    const { setShowNav } = useContext(CardNavContext);
    const { accounts, MAPopup, MAPopupDisplayed, accountIndex, setAccountIndex } = useAccountContext();
    const { showPreferencesLanding } = useDeliveryPreferencesContext();
    const [storage, storeValue] = useSessionStorage(storageKeys);
    const [showOfferPopup, setShowOfferPopup] = useState(false);
    const [bodyText, setBodyText] = useState("");
    const [redirectUrl, setRedirectUrl] = useState("");
    const [popupId, setPopupId] = useState(0);
    const [apiFailedToPost, setApiFailedToPost] = useState(0);
    const offerAccountHandleFound = accounts?.find((x) => x.showMultipleAccountsMessage === true)?.accountHandle;
    const offerAccountHandle = IsNullOrEmpty(offerAccountHandleFound) ? "" : offerAccountHandleFound;
    const [showUnLockCardSuccess, setShowUnLockCardSuccess] = useState(false);
    const { actionAllowed } = useDigitalActionsContext();
    const { ApplicationStatus } = useCms();
    const [appCms, setAppCms] = useState<ApplicationStatusCms>();

    useEffect(() => {
        ApplicationStatus().then((res) => {
            setAppCms(res.data);
        });
    }, []);

    async function maPopup(): Promise<AxiosResponse<MultipleAccountPopupResponse, any>> {
        return MAPopup(offerAccountHandle!);
    }

    async function maPopupDisplayed(id: number): Promise<AxiosResponse<MultipleAccountPopupResponse, any>> {
        return await MAPopupDisplayed(offerAccountHandle!, id);
    }

    useEffect(() => {
        if (location.state !== null) {
            if (location.state.DisplayUnlockMsg === "true") {
                setShowUnLockCardSuccess(true);
            }
        }
    }, []);

    return {
        hooks: { navigate, storage, storeValue, actionAllowed },
        state: {
            size,
            urlId: id,
            accounts,
            accountIndex,
            setAccountIndex,
            setShowNav,
            offerAccountHandle,
            showOfferPopup,
            setShowOfferPopup,
            bodyText,
            setBodyText,
            redirectUrl,
            setRedirectUrl,
            setPopupId,
            apiFailedToPost,
            setApiFailedToPost,
            showUnLockCardSuccess,
            showPreferencesLanding,
            appCms
        },
        api: { maPopup, maPopupDisplayed }
    };
}
