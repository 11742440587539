import { FormikHelpers } from "formik";
import { ReactElement, useEffect } from "react";
import { FormProps } from "../../../types/formProps";
import { EqualsIgnoreCase, FormatPhone, SetTitle } from "../../../utility";
import { ManageAlertsView } from "./manageAlertsView";
import { ManageAlertsFormFields, useManageAlertsViewModel } from "./manageAlertsViewModel";

export function ManageAlertsModel(): ReactElement {
    const { api, state, hooks } = useManageAlertsViewModel();

    useEffect(() => {
        if (!hooks.actionAllowed("alerts", state.accounts![state.accountIndex].accountHandle)) {
            hooks.navigate(`/account/dashboard`, { relative: "path" });
        }
    }, [state.accountIndex]);

    useEffect(() => {
        state.setShowNav(true);
        SetTitle("Alerts");

        return () => {
            state.setShowNav(false);
            SetTitle("");
        };
    }, []);

    useEffect(() => {
        hooks.insertSwrveEvent("Web_Alerts_Screen");
    }, []);

    useEffect(() => {
        state.setEnrolledMobileNumber("");
        state.setEnrolledMobileNumberFormatted("");
        state.setCanSubmit(false);
        state.setInitializeForm(true);
    }, []);

    useEffect(() => {
        switch (state.activeElement?.id) {
            case "mobilePhoneNumber":
                state.setFocused({ mobilePhoneNumber: true, confirmMobilePhoneNumber: false });
                break;
            case "confirmMobilePhoneNumber":
                state.setFocused({ mobilePhoneNumber: false, confirmMobilePhoneNumber: true });
                break;
            default:
        }
    }, [state.activeElement]);

    //Get the enrollment status in order to set the toggle and get phone number
    useEffect(() => {
        if (state.accountIndex >= 0) getAlertInfo();
    }, [state.accountIndex]);

    function getAlertInfo() {
        state.setIsEditing(false);
        state.setIsLoading(true);
        api.isEnrolledGet()
            .then((res) => {
                try {
                    state.setIsEnrolled(res.data[0].isEnrolled);
                    state.setOptInText(res.data[0].optInText);
                    state.setEnrolledMobileNumber(res.data[0].phoneNumber ? res.data[0].phoneNumber : "");
                    state.setEnrolledMobileNumberFormatted(
                        FormatPhone(res.data[0].phoneNumber ? res.data[0].phoneNumber : "").formatted
                    );

                    //sort category by alpha, then by display number
                    if (res.data[0].alerts) {
                        let tempAlerts = res.data[0].alerts;
                        if (tempAlerts.length > 0) {
                            let filtertedAlerts = tempAlerts.filter((allAlerts) => {
                                return allAlerts.alertCategory !== null;
                            });
                            tempAlerts = filtertedAlerts;

                            tempAlerts.sort(
                                (a, b) =>
                                    a.alertCategory.localeCompare(b.alertCategory) ||
                                    a.displayOrderNum - b.displayOrderNum
                            );
                        }
                        state.setOriginalAlerts(tempAlerts);
                    }
                } catch (err) {
                    console.log(err);
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                state.setIsLoading(false);
            });
    }

    //Enrolling in alerts
    useEffect(() => {
        if (state.acceptedManageAlertsTerms) {
            api.postManageAlertsAcceptedTerms()
                .then(async (res) => {
                    state.setIsEditing(false);
                    state.setIsEnrolled(true);
                    state.setAcceptedManageAlertsTerms(false);
                    state.setShowModalEnrollPhoneSuccess(true);
                })
                .catch((err) => {
                    console.log(err);
                    state.setShowModalError(true);
                })
                .finally(() => {});
        }
    }, [state.acceptedManageAlertsTerms]);

    //Updating phone number in alerts
    useEffect(() => {
        if (state.updateManageAlertsPhone) {
            api.postManageAlertsAcceptedTerms()
                .then(async (res) => {
                    state.setIsEditing(false);
                    state.setShowModalUpdatePhoneSuccess(true);
                })
                .catch((err) => {
                    console.log(err);
                    state.setShowModalError(true);
                })
                .finally(() => {
                    state.setUpdateManageAlertsPhone(false);
                });
        }
    }, [state.updateManageAlertsPhone]);

    //Turning off alerts
    useEffect(() => {
        if (state.turnOffManageAlertsPhone) {
            api.postManageAlertsAcceptedTerms()
                .then(async (res) => {
                    state.setIsEditing(false);
                    state.setIsEnrolled(false);
                })
                .catch((err) => {
                    console.log(err);
                    state.setShowModalError(true);
                })
                .finally(() => {
                    state.setTurnOffManageAlertsPhone(false);
                    state.setShowModalTurnOffAlertsQuestion(false);
                });
        }
    }, [state.turnOffManageAlertsPhone]);

    //editing phone number, input field value change
    const onChange = (bag: FormProps<ManageAlertsFormFields>) => {
        if (state.initializeForm) {
            initForm(bag);
        } else {
            validateInput(bag);
        }
    };

    //editing phone number, leaving input field
    const onBlur = (bag: FormProps<ManageAlertsFormFields>) => {
        if (state.initializeForm) {
            initForm(bag);
        } else {
            validateInput(bag);
        }
    };

    const initForm = (bag: FormProps<ManageAlertsFormFields>) => {
        bag.errors.mobilePhoneNumber = "";
        bag.errors.confirmMobilePhoneNumber = "";
        bag.touched.mobilePhoneNumber = false;
        bag.touched.confirmMobilePhoneNumber = false;
        if (!state.isEnrolled) {
            bag.values.mobilePhoneNumber = "";
            bag.values.confirmMobilePhoneNumber = "";
        }

        //either of these may cause a bad set state
        state.setCanSubmit(false);
        state.setInitializeForm(false);
    };

    //editing phone number, validation phone numbers
    const validateInput = (bag: FormProps<ManageAlertsFormFields>) => {
        if (bag.touched.mobilePhoneNumber || bag.touched.confirmMobilePhoneNumber || state.isEnrolled) {
            let isValidLength =
                bag.values.mobilePhoneNumber.length === 14 && bag.values.confirmMobilePhoneNumber.length === 14;
            let isSameNumber = EqualsIgnoreCase(bag.values.mobilePhoneNumber, bag.values.confirmMobilePhoneNumber);
            let isPhoneNumberUpdated =
                state.enrolledMobileNumberFormatted !== bag.values.mobilePhoneNumber && isValidLength;

            if (isValidLength && isSameNumber && isPhoneNumberUpdated) {
                state.setCanSubmit(true);
                bag.errors.mobilePhoneNumber = "";
                bag.errors.confirmMobilePhoneNumber = "";
            } else {
                if (!isSameNumber || !isValidLength) {
                    bag.errors.confirmMobilePhoneNumber = "Phone numbers do not match";
                    bag.errors.mobilePhoneNumber = " ";
                    bag.touched.mobilePhoneNumber = true;
                    bag.touched.confirmMobilePhoneNumber = true;
                }
                state.setCanSubmit(false);
            }
        }
    };

    const onCancelUpdatedPhoneNumber = () => {
        state.setIsEditing(false);
        state.setToggleDisabled(false);
    };

    const onSaveUpdatedPhoneNumber = (bag: FormProps<ManageAlertsFormFields>) => {
        const unformattedPhone = bag.values.mobilePhoneNumber.replace(/[^\d]/g, "");
        state.setEnrolledMobileNumber(unformattedPhone);
        state.setEnrolledMobileNumberFormatted(bag.values.mobilePhoneNumber);
        state.setUpdateManageAlertsPhone(true);
        state.setToggleDisabled(false);
        state.setIsEditing(false);
    };

    //enrolling phone number
    const onSubmit = (bag: FormProps<ManageAlertsFormFields>, actions: FormikHelpers<{}>) => {
        const unformattedPhone = bag.values.mobilePhoneNumber.replace(/[^\d]/g, "");
        state.setEnrolledMobileNumber(unformattedPhone);
        state.setEnrolledMobileNumberFormatted(bag.values.mobilePhoneNumber);
        state.setShowTermsModal(true);
    };

    const onToggle = () => {
        //Toggle off, turned on (not enrolled)
        if (!state.isEnrolled && !state.isEditing) {
            state.setIsEditing(true);

            state.setEnrolledMobileNumber("");
            state.setEnrolledMobileNumberFormatted("");
            state.setCanSubmit(false);
            state.setInitializeForm(true);

            //Toggle on, turned off (not enrolled)
        } else if (!state.isEnrolled && state.isEditing) {
            state.setIsEditing(false);

            state.setEnrolledMobileNumber("");
            state.setEnrolledMobileNumberFormatted("");
            state.setCanSubmit(false);
            state.setInitializeForm(true);

            //Toggle on, turned off (enrolled)
        } else if (state.isEnrolled && !state.isEditing) {
            state.setIsEditing(false);
            state.setShowModalTurnOffAlertsQuestion(true);

            //Toggle on, turned off (enrolled)
        } else if (state.isEnrolled && state.isEditing) {
            state.setIsEditing(false);
        }
    };

    const onEditAlertPhone = () => {
        state.setIsEditing(true);
        state.setToggleDisabled(true);
        state.setInitializeForm(true);
    };

    const onDeclineTerms = () => {
        state.setEnrolledMobileNumber("");
        state.setEnrolledMobileNumberFormatted("");
        state.setCanSubmit(false);
        state.setShowTermsModal(false);
        state.setIsEditing(false);
        state.setInitializeForm(true);
        hooks.navigate(`/account/summary/${state.accountIndex}/manage-alerts`);
    };

    const onAccept = () => {
        state.setShowTermsModal(false);
        state.setAcceptedManageAlertsTerms(true);
    };

    const onViewTerms = () => {
        state.setViewOnlyTermsAndConditions(true);
        state.setShowTermsModal(true);
    };

    const onCloseViewTerms = () => {
        state.setViewOnlyTermsAndConditions(false);
        state.setShowTermsModal(false);
    };

    const onEditSpecificAlerts = () => {
        state.setIsEditingSpecificAlerts(true);
        state.setIsEditingSpecificAlertsChanged(false);
        state.setToggleDisabled(true);
    };

    const onCancelEditSpecificAlerts = () => {
        state.setIsEditingSpecificAlerts(false);
        state.setIsEditingSpecificAlertsChanged(false);
        state.setToggleDisabled(false);
        getAlertInfo();
    };

    const onSaveSpecificAlerts = () => {
        state.setIsEditingSpecificAlerts(false);
        state.setIsEditingSpecificAlertsChanged(false);
        state.setToggleDisabled(false);
    };

    return (
        <ManageAlertsView
            {...state}
            onChange={onChange}
            onBlur={onBlur}
            onCancelUpdatedPhoneNumber={onCancelUpdatedPhoneNumber}
            onSaveUpdatedPhoneNumber={onSaveUpdatedPhoneNumber}
            onSubmit={onSubmit}
            onEditAlertPhone={onEditAlertPhone}
            onDeclineTerms={onDeclineTerms}
            onToggle={onToggle}
            onAccept={onAccept}
            onEditSpecificAlerts={onEditSpecificAlerts}
            onCancelEditSpecificAlerts={onCancelEditSpecificAlerts}
            onSaveSpecificAlerts={onSaveSpecificAlerts}
            onCloseViewTerms={onCloseViewTerms}
            onViewTerms={onViewTerms}
        />
    );
}
