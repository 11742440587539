import { LettersCms, useCms } from "../../../../api/backend";

export type DocumentsCMSData = {
    cms: LettersCms;
};

export async function DocumentsCMSLoader(): Promise<DocumentsCMSData> {
    const { Letters } = useCms();
    const cms = (await Letters()).data;

    return { cms };
}
