import { useFormikContext } from "formik";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useActiveElement } from "../../../hooks";
import { InputFieldView } from "./inputFieldView";

export type InputFieldControllerProps = {
    id: string;
    label?: string;
    placeholder?: string;
    children?: ReactElement;
    error?: string;
    type?: string;
    maxLength?: number;
    mask?: string;
    formatChars?: any;
    onChange?: Function;
    onBlur?: Function;
    canPaste?: boolean;
    stateValue?: StateValue;
    inputMode?: string;
    pattern?: string;
    ignoreTouched?: boolean;
    alwaysHighlightLine?: boolean;
    showErrorMessage?: boolean;
    highLightLineClass?: string;
    autoComplete?: string;
};

export type StateValue = {
    get: any;
    set: React.Dispatch<React.SetStateAction<any>>;
    isPrivate?: boolean;
    canPeek?: boolean;
};

export function InputFieldController(props: InputFieldControllerProps): ReactElement {
    const formik = useFormikContext<any>();
    const active = useActiveElement();
    const [updated, setUpdated] = useState(false);
    const maskChars = useRef(findUnique(props.mask ?? "", ["a", "9", "*"]).split(""));
    const regEx = useRef(new RegExp(`[^${maskChars.current}]`, "g"));
    const rawEx = useRef(new RegExp(`[${maskChars.current}]`, "g"));
    const isPrivate = props.stateValue?.isPrivate === true;
    const canPeek = props.stateValue?.canPeek === true;
    const [show, setShow] = useState(false);
    const [touched, setTouched] = useState(false);
    const [pasted, setPasted] = useState(false);

    const onPaste = (e: React.ClipboardEvent) => {
        if (props.canPaste === true) {
            setPasted(true);
            let data = e.clipboardData.getData("Text");

            if (props.stateValue && props.stateValue.isPrivate) {
                props.stateValue.set(props.stateValue.get + data);
                formik.values[props.id] = formik.values[props.id] + data;
            } else if (props.stateValue) {
                props.stateValue.set(props.stateValue.get + data);
            }
            setUpdated(true);
        } else {
            e.preventDefault();
        }
    };

    useEffect(() => {
        formik.touched[props.id] = false;

        if (props.stateValue) {
            formik.values[props.id] = props.stateValue.get;
        }
    }, []);

    useEffect(() => {
        if (isPrivate && show) {
            const timer = setTimeout(() => {
                setShow(false);
            }, 10_000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [show]);

    useEffect(() => {
        if (props.stateValue?.get === "" && formik.values[props.id] !== "") {
            formik.values[props.id] = "";
        }
    }, [props.stateValue?.get]);

    useEffect(() => {
        if (!touched && active?.id === props.id) {
            setTouched(true);
        }
    }, [active]);

    useEffect(() => {
        if (props.stateValue && isPrivate) {
            let rawValue: string = props.stateValue.get;
            let hidden = "••••••••••••••••••••••••••••••••••••••••"; //40

            let match: RegExpExecArray | null;
            let maskIndexs = [] as { char: string; index: number }[];

            while ((match = rawEx.current.exec(props.mask ?? "")) !== null) {
                maskIndexs.push({ char: match[0], index: match.index });
            }

            maskIndexs.forEach((x) => {
                rawValue = rawValue.replaceAll(x.char, "");
            });

            let hiddenLength = maskIndexs.filter((x) => x.index <= rawValue.length).length;

            if (!show) {
                formik.values[props.id] = hidden.substring(0, rawValue.length + hiddenLength);
            }
            if (show) {
                formik.values[props.id] = rawValue;
            }
            setUpdated(true);
        }
    }, [show]);

    useEffect(() => {
        if (props.stateValue && isPrivate && !show && !pasted) {
            let value = formik.values[props.id];
            let rawValue = props.stateValue.get;

            let match: RegExpExecArray | null;
            let maskIndexs = [] as { char: string; index: number }[];

            while ((match = rawEx.current.exec(props.mask ?? "")) !== null) {
                maskIndexs.push({ char: match[0], index: match.index });
            }

            maskIndexs.forEach((x) => {
                rawValue = rawValue.replaceAll(x.char, "");
            });

            // ================ Unmask the Value ============================

            for (let i = 0; i < maskIndexs.length; i++) {
                if (value.length >= maskIndexs[i].index) {
                    value = replaceAt(value, maskIndexs[i].index - i, "");
                }
            }

            if (value.length > rawValue.length + 1) {
                value = value.substring(0, rawValue.length);
            }

            // ================ Update raw Value ============================

            let newInput = findUnique(value, ["•", ...maskChars.current]);
            let newIndex = value.lastIndexOf(newInput);
            rawValue = rawValue.substring(0, value.length);
            rawValue = replaceAt(rawValue, newIndex, newInput);

            props.stateValue.set(rawValue);

            // ================ Hide the Display ============================

            if (value && value.slice(-1) !== "•") {
                let adjust = 1;

                value =
                    value.substring(0, value.length - adjust).replaceAll(regEx.current, "•") +
                    value.substring(value.length - adjust);
            } else if (value && newInput) {
                value =
                    value.substring(0, newIndex).replaceAll(regEx.current, "•") +
                    newInput +
                    value.substring(newIndex + newInput.length, value.length);
            }

            // ================ Remask the Value ============================

            for (let i = 0; i < maskIndexs.length; i++) {
                if (value.length >= maskIndexs[i].index) {
                    value = insertAt(value, maskIndexs[i].index, maskIndexs[i].char);
                }
            }

            // ===============================================================

            formik.values[props.id] = value;

            const timer = setTimeout(() => {
                formik.values[props.id] = formik.values[props.id].replaceAll(regEx.current, "•");
                setUpdated(true);
            }, 0);

            return () => {
                clearTimeout(timer);
            };
        } else if (props.stateValue && !pasted) {
            let value = formik.values[props.id];

            props.stateValue.set(value);

            setUpdated(true);
        }
    }, [formik.values[props.id]]);

    useEffect(() => {
        if (updated) {
            setUpdated(false);
            setPasted(false);
        }
    }, [updated]);

    return (
        <InputFieldView
            {...props}
            onPaste={onPaste}
            isPrivate={isPrivate}
            canPeek={isPrivate && canPeek}
            show={show}
            setShow={setShow}
            ignoreTouched={props.ignoreTouched === true}
            touched={touched}
        />
    );
}

function findUnique(str: string, ignore: string[]): string {
    ignore.forEach((x) => (str = str.replaceAll(x, "")));

    let uniq = "";

    for (let i = 0; i < str.length; i++) {
        if (uniq.includes(str[i]) === false) {
            uniq += str[i];
        }
    }
    return uniq;
}

function replaceAt(str: string, index: number, newValue: string): string {
    if (newValue.length === 0) {
        return str.substring(0, index) + newValue + str.substring(index + 1);
    } else {
        return str.substring(0, index) + newValue + str.substring(index + newValue.length);
    }
}

function insertAt(str: string, index: number, newValue: string): string {
    return str.substring(0, index) + newValue + str.substring(index);
}
