import { browserName, browserVersion } from "react-device-detect";
import { BrowserMessageCms, BrowserVersionCms, useCms } from "../../../api/backend";

export type BrowserCompatibilityData = {
    cms: BrowserMessageCms;
    current: boolean;
    supported: boolean;
};

export async function BrowserCompatibilityLoader(): Promise<BrowserCompatibilityData> {
    const { BrowserMessage, BrowserVersion } = useCms();

    if (sessionStorage["browserCompatibility"] === undefined) {
        const cms = (await BrowserMessage()).data.BrowserCompatibilityWarning;
        const browsers = (await BrowserVersion()).data;
        const current = isBrowserCurrent(browsers.GlobalBrowserVersionConfiguration.BrowserVersions);
        const supported = isBrowserSupported(browsers.GlobalBrowserVersionConfiguration.BrowserVersions);

        let data = { cms, current, supported };

        sessionStorage.setItem("browserCompatibility", JSON.stringify(data));

        return data;
    } else {
        return JSON.parse(sessionStorage["browserCompatibility"]) as BrowserCompatibilityData;
    }
}

function isBrowserSupported(browsers: BrowserVersionCms[]): boolean {
    return browsers.find((b) => b.Active && b.BrowserFamily === browserName) !== undefined;
}

function isBrowserCurrent(browsers: BrowserVersionCms[]): boolean {
    var matchingBrowser = browsers.find((b) => b.Active && b.BrowserFamily === browserName);

    if (matchingBrowser !== undefined) {
        return Number(browserVersion) >= Number(matchingBrowser.MajorVersionNumber);
    }

    return false;
}
