import { ReactElement, useEffect } from "react";
import "./buttonPro.scss";
import { useButtonProViewModel } from "./buttonProViewModel";
import { ButtonProView } from "./buttonProView";

export type ButtonProProps = {
    id?: string;
    className?: string;
    type?: ButtonType;
    disabled?: boolean;
    onClick?: Function;
    content?: any;
    children?: any;
    variant?: ButtonVariant;
    unblock?: boolean;
    btnSize?: ButtonSize;
    isSubmitting?: boolean;
    tabIndex?: number;
};

type ButtonSize = "lg" | "md" | "sm";
type ButtonType = "button" | "reset" | "submit";
export type ButtonVariant =
    | "primary"
    | "secondary"
    | "tertiary"
    | "primary-neg"
    | "secondary-neg"
    | "tertiary-neg"
    | "neutral"
    | "icon";

function defaults(props: ButtonProProps): ButtonProProps {
    return { disabled: false, variant: "primary", type: "button", ...props };
}

export function ButtonPro(props: ButtonProProps): ReactElement {
    props = defaults(props);
    const { state } = useButtonProViewModel(props);

    useEffect(() => {
        if (props.isSubmitting !== undefined && !props.isSubmitting && state.clickBlock) {
            clearTimeout(state.timer.current);
            state.setClickBlock(false);
        }

        return () => {
            if (props.isSubmitting === true) clearTimeout(state.timer.current);
        };
    }, [props.isSubmitting, state.clickBlock]);

    const mouseUpHook = () => {
        return () => {
            if (!state.clickBlock && !props.unblock) {
                setTimeout(() => {
                    state.setClickBlock(true);
                });

                state.timer.current = setTimeout(
                    () => {
                        state.setClickBlock(false);
                    },
                    props.type === "submit" ? 60_000_000 : 1_000
                );
            }
        };
    };

    return <ButtonProView {...props} {...state} mouseUpHook={mouseUpHook} />;
}
