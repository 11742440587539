import dayjs from "dayjs";
import { ReactElement, useEffect } from "react";
import { Modal, ModalProps } from "../../generic";
import { SessionTimeoutView } from "./sessionTimeoutView";
import { useSessionTimeoutViewModel } from "./sessionTimeoutViewModel";

type SessionTimeoutModelProps = {
    updateSession: Function;
};

export function SessionTimeoutModal(props: ModalProps & SessionTimeoutModelProps): ReactElement {
    return (
        <Modal show={props.show} isSessionTimeout={true}>
            <SessionTimeoutModel {...props} />
        </Modal>
    );
}

export function SessionTimeoutModel(props: SessionTimeoutModelProps): ReactElement {
    const { hooks, state, api } = useSessionTimeoutViewModel();

    useEffect(() => {
        hooks.start();
    }, []);

    useEffect(() => {
        if (hooks.expired(state.sessionTimes.endTime)) {
            hooks.stop();
            onTimeout();
        }

        let now = dayjs();
        state.setTicker(state.sessionTimes.endTime.diff(now, "second"));
    }, [state.tick]);

    async function extendSession() {
        hooks.stop();
        api.RefreshToken(state.refresh_token, false);
        props.updateSession();
    }

    function onLogout() {
        api.logout();
        hooks.stop();
        hooks.swrveShutdown();
        hooks.clearConsumerGuid();
        hooks.clearJwt();
        hooks.removeCookie("OptanonConsent", { path: "/" });
        hooks.removeCookie("OptanonAlertBoxClosed", { path: "/" });
        state.setShowSessionTimer(false);
        hooks.clearIdentity("all");
        hooks.navigate("/logout");
    }

    function onTimeout() {
        hooks.stop();
        hooks.clearConsumerGuid();
        hooks.clearJwt();
        hooks.removeCookie("OptanonConsent", { path: "/" });
        hooks.removeCookie("OptanonAlertBoxClosed", { path: "/" });
        state.setShowSessionTimer(false);
        hooks.clearIdentity("all");
        hooks.navigate("/timeout");
    }

    return <SessionTimeoutView {...state} extendSession={extendSession} logOut={onLogout} />;
}
