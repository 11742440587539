import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { GenericModal, ModalProps } from "../../../generic";

type InvalidSessionProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export function InvalidSession(props: ModalProps & InvalidSessionProps): ReactElement {
    const navigate = useNavigate();

    const modalProps = {
        show: props.show,
        headerText: "Session Expired",
        bodyText: "Your session has expired.",
        primaryButton: {
            text: "Start Over",
            action: () => {
                props.setShow(false);
                navigate("/auth/login");
            }
        },
        isBranded: true
    };

    return <GenericModal {...modalProps} />;
}
