import { AxiosResponse } from "axios";
import { useContext, useState } from "react";
import { IntermediateWarningCms, useCms } from "../../../api/backend";
import { AppDownloadBannerContext } from "../../../contexts";
import { useSessionStorage, useViewport } from "../../../hooks";
import { Size } from "../../../types";

type AppDownloadBannerViewModel = {
    hooks: AppDownloadBannerHooks;
    state: AppDownloadBannerState;
    api: AppDownloadBannerApi;
};

type AppDownloadBannerHooks = {
    storage: { [x: string]: any };
    storeValue: (name: string, value: any) => void;
};

export type AppDownloadBannerState = {
    size: Size;
    showIntermediate: boolean;
    setShowIntermediate: React.Dispatch<React.SetStateAction<boolean>>;
    intermediateDest: string;
    setIntermediateDest: React.Dispatch<React.SetStateAction<string>>;
    cms: IntermediateWarningCms;
    setCms: React.Dispatch<React.SetStateAction<IntermediateWarningCms>>;
    setShowBanner: React.Dispatch<React.SetStateAction<boolean>>;
};

type AppDownloadBannerApi = {
    IntermediateWarning: () => Promise<AxiosResponse<IntermediateWarningCms, any>>;
};

const storageKeys = ["ShowAppBanner", "intermediateWarning"];

export function useAppDownloadBannerViewModel(): AppDownloadBannerViewModel {
    const { size } = useViewport(false, true);
    const [showIntermediate, setShowIntermediate] = useState(false);
    const [intermediateDest, setIntermediateDest] = useState("");
    const [cms, setCms] = useState({} as IntermediateWarningCms);
    const [storage, storeValue] = useSessionStorage(storageKeys);
    const { setShowBanner } = useContext(AppDownloadBannerContext);
    const { IntermediateWarning } = useCms();

    return {
        hooks: { storage, storeValue },
        state: {
            size,
            showIntermediate,
            setShowIntermediate,
            intermediateDest,
            setIntermediateDest,
            cms,
            setCms,
            setShowBanner
        },
        api: { IntermediateWarning }
    };
}
