import { ReactElement } from "react";
import { Alert, AsyncElement } from "../../generic";
import { ArrowDown, ArrowUp } from "../../generic/icons";
import { ButtonPro } from "../../generic/inputs/buttonPro/buttonPro";
import "./activity.scss";
import { ActivityDateFilter } from "./activityDateFilter/activityDateFilter";
import { ActivityDownload } from "./activityDownload/activityDownload";
import { ActivityState } from "./activityViewModel";

export type ActivityViewProps = ActivityState & {
    onViewMore: Function;
};

function ActivityView(props: ActivityViewProps): ReactElement {
    return (
        <div id="activity">
            <div className={`activity-header${props.size}`}>
                <div className={`activity-header-left`}>
                    <h5>Activity</h5>
                </div>
                <div className={`activity-header-middle`}>
                    {props.activityErr ? <></> : <ActivityDateFilter {...props} />}
                </div>
                <div className="activity-header-right">
                    {props.filteredHistory.length > 0 ? (
                        <ActivityDownload filteredHistory={props.filteredHistory} />
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <AsyncElement loading={props.loading}>
                {props.activityErr ? (
                    <Alert level="async-error">
                        Your activity is unavailable due to a technical issue. Please check back later.
                    </Alert>
                ) : (
                    <div className="activity-list" data-testid="activity-list">
                        {props.rows.length > 0 ? (
                            props.rows
                        ) : (
                            <div className="activity-noList">
                                <h5>No activity available</h5>
                            </div>
                        )}
                    </div>
                )}
            </AsyncElement>

            <div className="activity-footer">
                {props.filteredHistory.length > 3 ? (
                    <ButtonPro
                        variant="neutral"
                        className="activity-footer-more"
                        unblock
                        onClick={() => props.onViewMore()}
                    >
                        <h6>View {!props.seeMore ? "More" : "Less"}</h6>
                        {!props.seeMore ? <ArrowDown /> : <ArrowUp />}
                    </ButtonPro>
                ) : (
                    <p></p>
                )}
                <p>
                    {props.filteredHistory.length} result{props.filteredHistory.length !== 1 ? "s" : ""}
                </p>
            </div>
        </div>
    );
}

export default ActivityView;
