import { EnrollmentCmsModel, useCms } from "../../../api/backend";

export type EnrollTermsData = {
    cms: EnrollmentCmsModel;
};

export async function EnrollTermsLoader(): Promise<EnrollTermsData> {
    const { Enrollment } = useCms();
    let cms = (await Enrollment()).data;

    return { cms };
}
