import { ReactElement } from "react";
import { IntermediateWarningCms } from "../../../api/backend";
import { Modal, ModalProps } from "../../generic";
import IntermediateWarningView from "./intermediateWarningView";
import { useIntermediateWarningViewModel } from "./intermediateWarningViewModel";

export type IntermediateWarningProps = {
    dest: string;
    cms: IntermediateWarningCms;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    callback?: Function;
    openNewWindow?: boolean;
    setOpenNewWindow?: React.Dispatch<React.SetStateAction<boolean>>;
};

export function IntermediateWarningModal(props: ModalProps & IntermediateWarningProps): ReactElement {
    return (
        <Modal show={props.show}>
            <IntermediateWarningModel {...props} />
        </Modal>
    );
}

function IntermediateWarningModel(props: IntermediateWarningProps): ReactElement {
    const { state } = useIntermediateWarningViewModel(props);

    function onLeave() {
        if (props.callback !== undefined) {
            props.callback();
        }

        props.setShow(false);

        if (props.openNewWindow) {
            window.open(props.dest, "_blank");
        } else {
            window.location.href = props.dest;
        }

        if (props.setOpenNewWindow !== undefined) {
            props.setOpenNewWindow(false);
        }
    }

    function onStay() {
        props.setShow(false);
    }

    return <IntermediateWarningView {...state} onLeave={onLeave} onStay={onStay} />;
}
