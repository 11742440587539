import { AxiosResponse } from "axios";
import { useState } from "react";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import {
    MfaOptionsRequest,
    MfaOptionsResponse,
    ValidateIdentityByIdRequest,
    ValidateUsernameRequest,
    useEnrollmentContext
} from "../../../api/backend";
import { MFAOptions } from "../../../api/identityServer";
import { useMfaContext } from "../../../contexts";
import { SecurityType } from "../../../contexts/mfaContext";
import { useActiveElement, useViewport } from "../../../hooks";
import { Size } from "../../../types";

type EnrollCredentialsViewModel = {
    hooks: EnrollCredentialsHooks;
    state: EnrollCredentialsState;
    api: EnrollCredentialsApi;
};

type EnrollCredentialsHooks = {
    navigate: NavigateFunction;
};

export type EnrollCredentialsState = {
    size: Size;
    searchParams: URLSearchParams;
    securityType: SecurityType | undefined;
    setSecurityType: (value: SecurityType) => void;
    setMfaOptions: (value: MFAOptions) => void;
    activeElement: Element | null;
    usernameError: string;
    setUsernameError: React.Dispatch<React.SetStateAction<string>>;
    password: string;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    confirmPass: string;
    setConfirmPass: React.Dispatch<React.SetStateAction<string>>;
    emailError: string;
    setEmailError: React.Dispatch<React.SetStateAction<string>>;
    canContinue: boolean;
    setCanContinue: React.Dispatch<React.SetStateAction<boolean>>;
    usernameTouched: boolean;
    setUsernameTouched: React.Dispatch<React.SetStateAction<boolean>>;
    passwordTouched: boolean;
    setPasswordTouched: React.Dispatch<React.SetStateAction<boolean>>;
    confirmTouched: boolean;
    setConfirmTouched: React.Dispatch<React.SetStateAction<boolean>>;
    emailTouched: boolean;
    setEmailTouched: React.Dispatch<React.SetStateAction<boolean>>;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    subtitle: string;
    setSubtitle: React.Dispatch<React.SetStateAction<string>>;
    sessionId: string;
    setSessionId: React.Dispatch<React.SetStateAction<string>>;
    showError: boolean;
    setShowError: React.Dispatch<React.SetStateAction<boolean>>;
};

type EnrollCredentialsApi = {
    ValidateIdentityById: (request: ValidateIdentityByIdRequest) => Promise<AxiosResponse<any, any>>;
    ValidateUsername: (request: ValidateUsernameRequest) => Promise<AxiosResponse<any, any>>;
    MfaOptions: (request: MfaOptionsRequest) => Promise<AxiosResponse<MfaOptionsResponse, any>>;
};

export function useEnrollCredentialsViewModel(): EnrollCredentialsViewModel {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { size } = useViewport();
    const { securityType, setSecurityType, setMfaOptions } = useMfaContext();
    const { ValidateIdentityById, ValidateUsername, MfaOptions } = useEnrollmentContext();
    const activeElement = useActiveElement();
    const [password, setPassword] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [canContinue, setCanContinue] = useState(false);
    const [usernameTouched, setUsernameTouched] = useState(false);
    const [passwordTouched, setPasswordTouched] = useState(false);
    const [confirmTouched, setConfirmTouched] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [subtitle, setSubtitle] = useState("Step 2 of 4");
    const [sessionId, setSessionId] = useState("");
    const [emailError, setEmailError] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [showError, setShowError] = useState(false);

    return {
        hooks: {
            navigate
        },
        state: {
            size,
            searchParams,
            securityType,
            setSecurityType,
            setMfaOptions,
            activeElement,
            usernameError,
            setUsernameError,
            password,
            setPassword,
            confirmPass,
            setConfirmPass,
            emailError,
            setEmailError,
            canContinue,
            setCanContinue,
            usernameTouched,
            setUsernameTouched,
            passwordTouched,
            setPasswordTouched,
            confirmTouched,
            setConfirmTouched,
            emailTouched,
            setEmailTouched,
            showModal,
            setShowModal,
            subtitle,
            setSubtitle,
            sessionId,
            setSessionId,
            showError,
            setShowError
        },
        api: {
            ValidateIdentityById,
            ValidateUsername,
            MfaOptions
        }
    };
}
