import { useSettingsContext } from "../../../api/backend";
import * as icon from "../../generic/icons";

type SearchHelpTopicsProps = {
    searchText: string;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    onSearch: Function;
};

export function SearchHelpTopics(props: SearchHelpTopicsProps) {
    const { appSettings } = useSettingsContext();

    return (
        <>
            <div
                className="w-100 input-group search-group"
                onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => {
                    if (e.key === "Enter") {
                        props.onSearch();
                    }
                }}
            >
                <span className="input-group-text" id="basic-addon1">
                    <icon.Search className="search-group-icon-color" onClick={() => props.onSearch()} />
                </span>
                <input
                    type={(!appSettings?.featureFlags?.knowledgeManagement ?? false) ? "text" : "search"}
                    className="form-control search-input"
                    placeholder={
                        (!appSettings?.featureFlags?.knowledgeManagement ?? false)
                            ? "Search temporarily unavailable"
                            : "Search..."
                    }
                    disabled={!appSettings?.featureFlags?.knowledgeManagement ?? true}
                    aria-label="search-box"
                    aria-describedby="basic-addon1"
                    value={props.searchText}
                    onChange={(x) => {
                        props.setSearchText(x.target.value);
                    }}
                />
            </div>
        </>
    );
}
