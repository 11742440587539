import { isAndroid, isFirefox, isMobile, isMobileSafari, isSafari, isWindows } from "react-device-detect";

export function isAndroidBrowser(): boolean {
    return isAndroid;
}

export function isWindowsPhoneBrowser(): boolean {
    return isWindows && isMobile;
}

export function isAndroidFirefox(): boolean {
    return isFirefox && isAndroid;
}

export function isIOS(): boolean {
    return isMobileSafari || isSafari;
}
