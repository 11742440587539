import { AxiosResponse } from "axios";
import { useState } from "react";
import { Statement, useStatementsContext } from "../../../../api/backend";
import { useExpandableRowContext } from "../../../../contexts";

type StatementsRowViewModel = {
    hooks: StatementsRowHooks;
    state: StatementsRowState;
};

export type StatementsRowHooks = {
    newRowExpanded: (index: number) => void;
    getStatement: (accountHandle: string, statementId: string) => Promise<AxiosResponse<Statement, any>>;
};

export type StatementsRowState = {
    expanded: boolean;
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    curIndex: number | undefined;
    setCurIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
    showLoading: boolean;
    setShowLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useStatementsRowViewModel(): StatementsRowViewModel {
    const { GetById } = useStatementsContext();
    const { curIndex, setCurIndex, newRowExpanded } = useExpandableRowContext();
    const [expanded, setExpanded] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const getStatement = (accountHandle: string, statementId: string) => {
        return GetById(accountHandle, statementId);
    };

    return {
        hooks: { newRowExpanded, getStatement },
        state: { expanded, setExpanded, curIndex, setCurIndex, showLoading, setShowLoading }
    };
}
