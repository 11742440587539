import { ReactElement } from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { AppDownloadBanner, SearchHelpTopics } from "../../..";
import { MarketingSiteUrl } from "../../../../res/react-env-variables";
import { DebugInfoHeader } from "../../../devTools/debugInfo/debugInfo";
import { Login, PBCLogoSm, Search } from "../../../generic/icons";
import "./appHeaderPublic.scss";
import { AppHeaderPublicState } from "./appHeaderPublicViewModel";

type AppHeaderPublicViewProps = AppHeaderPublicState & {
    handleClose: Function;
    toggleOffCanvas: Function;
    onLogin: Function;
    onSearch: Function;
};

function AppHeaderPublicView(props: AppHeaderPublicViewProps): ReactElement {
    return (
        <div id="appHeaderPublic">
            {props.showBanner && !props.showOffCanvas ? <AppDownloadBanner /> : <></>}
            <Navbar id="appHeaderPublic-inner" key={props.szKey.toString()} expand={props.szKey}>
                <DebugInfoHeader />
                <Container id="appHeaderPublic-container" fluid>
                    <div id={`appHeaderPublic-content${props.size}`}>
                        <Navbar.Brand id="appHeaderPublic-brand" href={MarketingSiteUrl}>
                            <PBCLogoSm id="appHeaderPublic-logo-sm" />
                        </Navbar.Brand>
                        <Navbar.Toggle
                            className={`appHeaderPublic-toggle${props.showOffCanvas ? "" : " collapsed collapsed-mm"}`}
                            onClick={() => {
                                props.toggleOffCanvas();
                            }}
                            aria-controls={`offcanvasNavbar-expand-${props.szKey}`}
                            data-testid="toggleNavPublic"
                        >
                            <span className="icon-bar top-bar"></span>
                            <span className="icon-bar middle-bar"></span>
                            <span className="icon-bar bottom-bar"></span>
                        </Navbar.Toggle>
                        <Navbar.Offcanvas
                            restoreFocus={false}
                            show={props.showOffCanvas}
                            onHide={props.handleClose}
                            id={`offcanvasNavbar-expand-${props.szKey}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${props.szKey}`}
                            placement="start"
                        >
                            <Offcanvas.Body>
                                {props.szKey ? (
                                    <>
                                        <Nav className="appHeaderPublic-nav">
                                            <Search
                                                className="appHeaderPublic-icon-sf"
                                                caption="Search"
                                                onClick={() => props.onSearch()}
                                            />
                                            <Login
                                                className="appHeaderPublic-icon-sf"
                                                caption="Log In"
                                                onClick={() => {
                                                    props.onLogin();
                                                }}
                                            />
                                        </Nav>
                                    </>
                                ) : (
                                    <>
                                        <div className="offcanvas-body-mobile-menu">
                                            <div className="offcanvas-body-split">
                                                <SearchHelpTopics {...props} />
                                            </div>
                                            <div
                                                className="offcanvas-login"
                                                onClick={() => {
                                                    props.onLogin();
                                                }}
                                            >
                                                <Login className="appHeaderPublic-icon-sf" />
                                                <h6>Log In</h6>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </div>
                </Container>
            </Navbar>
        </div>
    );
}

export default AppHeaderPublicView;
