import { useState } from "react";
import { useExpandableRowContext } from "../../../../contexts";
import { useViewport } from "../../../../hooks";
import { Size } from "../../../../types";
import { ActivityRowProps } from "./activityRow";

type ActivityRowViewModel = {
    hooks: ActivityRowHooks;
    state: ActivityRowState;
};

export type ActivityRowHooks = {
    newRowExpanded: (index: number) => void;
};

export type ActivityRowState = {
    size: Size;
    expanded: boolean;
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    curIndex: number | undefined;
    setCurIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
    debitOrCredit: string;
};

export function useActivityRowViewModel(props: ActivityRowProps): ActivityRowViewModel {
    const { size } = useViewport(false, true);
    const { curIndex, setCurIndex, newRowExpanded } = useExpandableRowContext();
    const [expanded, setExpanded] = useState(false);
    let debitOrCredit: string = props.activity?.TransactionAmount < 0 ? "credit" : "debit";

    return {
        hooks: { newRowExpanded },
        state: { size, expanded, setExpanded, curIndex, setCurIndex, debitOrCredit }
    };
}
