import dayjs from "dayjs";
import { FormikHelpers } from "formik";
import { ReactElement, useEffect } from "react";
import { ValidationErrors } from "../../../../api/backend";
import { FormProps } from "../../../../types";
import { EqualsIgnoreCase } from "../../../../utility";
import RequestAuthUserFormView, { RequestAuthUserFormFields } from "./requestAuthUserFormView";
import { useRequestAuthUserFormViewModel } from "./requestAuthUserFormViewModel";

export type RequestAuthUserFormProps = {
    setShowSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
    setShowError: React.Dispatch<React.SetStateAction<boolean>>;
};

export function RequestAuthUserFormModal(props: RequestAuthUserFormProps): ReactElement {
    return <RequestAuthUserFormModel {...props} />;
}

function RequestAuthUserFormModel(props: RequestAuthUserFormProps): ReactElement {
    const { state, api, hooks } = useRequestAuthUserFormViewModel(props);

    useEffect(() => {
        if (!hooks.actionAllowed("requestAuthorizedUser", state.account.accountHandle)) {
            hooks.navigate(`/account/dashboard`, { relative: "path" });
        }
    });

    useEffect(() => {
        switch (state.activeElement?.id) {
            case "firstName":
                state.setFocused({ firstName: true, lastName: false, dateOfBirth: false });
                break;
            case "lastName":
                state.setFocused({ firstName: false, lastName: true, dateOfBirth: false });
                break;
            case "dateOfBirth":
                state.setFocused({ firstName: false, lastName: false, dateOfBirth: true });
                break;
            default:
        }
    }, [state.activeElement]);

    function onChange(bag: FormProps<RequestAuthUserFormFields>) {
        bag.values.firstName = bag.values.firstName.trimStart();
        bag.values.lastName = bag.values.lastName.trimStart();

        let isValidName = bag.values.firstName.length > 0 && bag.values.lastName.length > 0;
        let isValidLength = bag.values.firstName.length + bag.values.lastName.length <= 21;
        let isOldEnough = dayjs().diff(dayjs(bag.values.dateOfBirth), "year") >= 13;
        let isValidDate = dayjs(bag.values.dateOfBirth, "MM/DD/YYYY").format("MM/DD/YYYY") === bag.values.dateOfBirth;
        let isCardHolder =
            EqualsIgnoreCase(bag.values.firstName, state.account.firstName) &&
            EqualsIgnoreCase(bag.values.lastName, state.account.lastName) &&
            bag.values.dateOfBirth === dayjs(state.account.dob).format("MM/DD/YYYY");

        state.setCanSubmit(isValidName && isValidLength && isOldEnough && !isCardHolder && isValidDate);

        if (!isValidLength) {
            bag.errors.lastName = "First and Last Name cannot contain more than 21 characters";
        }
        if (!isValidDate && bag.values.dateOfBirth.length === 10) {
            bag.errors.dateOfBirth = "Invalid date";
        } else if (!isOldEnough && bag.values.dateOfBirth.length === 10) {
            bag.errors.dateOfBirth = "Authorized user must be at least 13 years of age";
        }

        if (isCardHolder) {
            bag.errors.lastName = "Authorized user cannot be the same as the primary cardholder";
        }
    }

    function onBlur(bag: FormProps<RequestAuthUserFormFields>) {
        if (bag.touched.firstName === true && bag.values.firstName.length === 0) {
            bag.errors.firstName = "First Name cannot be blank";
        }
        if (bag.touched.lastName === true && bag.values.lastName.length === 0) {
            bag.errors.lastName = "Last Name cannot be blank";
        }
        if (bag.touched.dateOfBirth === true && bag.values.dateOfBirth.length === 0) {
            bag.errors.dateOfBirth = "Date of Birth cannot be blank";
        } else if (bag.touched.dateOfBirth === true && bag.values.dateOfBirth.length < 10) {
            bag.errors.dateOfBirth = "Invalid date";
        }
    }

    function onSubmit(values: RequestAuthUserFormFields, actions: FormikHelpers<RequestAuthUserFormFields>) {
        api.additionalCardPost({
            FirstName: values.firstName,
            LastName: values.lastName,
            DOB: new Date(values.dateOfBirth)
        })
            .then((res) => {
                if (res.data.SaveResult.NewId > 0) {
                    props.setShowSubmitted(true);
                } else {
                    props.setShowError(true);
                }
            })
            .catch((err) => {
                if (err.response?.status === 400) {
                    let errors = err.response?.data as ValidationErrors;
                    let errorField = Object.keys(errors.errors)[0];

                    if (errorField === "RequestSameAsPrimary") {
                        actions.setErrors({
                            lastName: "Authorized user cannot be the same as the primary cardholder"
                        });
                    } else if (errorField === "NameGreaterThan21") {
                        actions.setErrors({
                            lastName: "First and Last Name cannot contain more than 21 characters"
                        });
                    } else if (errorField === "DOB") {
                        actions.setErrors({
                            dateOfBirth: "Authorized user must be at least 13 years of age"
                        });
                    } else if (errorField === "FirstName") {
                        actions.setErrors({
                            firstName: "First Name cannot be blank"
                        });
                    } else if (errorField === "LastName") {
                        actions.setErrors({
                            lastName: "Last Name cannot be blank"
                        });
                    }
                }

                props.setShowError(true);
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }

    return <RequestAuthUserFormView {...props} {...state} onChange={onChange} onBlur={onBlur} onSubmit={onSubmit} />;
}
