import { ReactElement, useEffect } from "react";
import { ActivitySummaryView } from "./activitySummaryView";
import { useActivitySummaryViewModel } from "./activitySummaryViewModel";

export function ActivitySummary(): ReactElement {
    const { state } = useActivitySummaryViewModel();

    useEffect(() => {
        state.setAccountIndex(state.urlId);
        state.setShowNav(true);

        return () => {
            state.setShowNav(false);
        };
    }, []);

    return <ActivitySummaryView {...state} />;
}
