import { useEffect, useState } from "react";

type PTheme = "light" | "dark";

export const useThemeSwitcher = () => {
    const [theme, setTheme] = useState<PTheme>("light");

    useEffect(() => {
        if (theme === "light") {
        } else {
        }
    }, [theme]);

    return { theme, setTheme };
};
