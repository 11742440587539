import { AxiosError } from "axios";
import { FormikHelpers } from "formik";
import { ReactElement, useEffect } from "react";
import { ValidationErrors } from "../../../api/backend";
import { Encrypt, EqualsIgnoreCase, HelpLine, Regex, UUID } from "../../../utility";
import { ForgotLoginView, ForgotPasswordFields } from "./forgotLoginView";
import { useForgotLoginViewModel } from "./forgotLoginViewModel";

export function ForgotLoginModel(): ReactElement {
    const { hooks, state, api } = useForgotLoginViewModel();

    useEffect(() => {
        state.setSecurityType(state.securityType!);

        if (state.securityType === "Enrollment" && state.searchParams.get("id") === null) {
            let id = UUID.New();
            hooks.navigate({
                pathname: "/auth/enroll",
                search: `?id=${id}`
            });
        }
    }, []);

    useEffect(() => {
        if (state.securityType === "Enrollment")
            state.setCanContinue(state.ssn.replaceAll("-", "").length === 9 && state.refNumber.length > 3);
        else
            state.setCanContinue(
                state.ssn.replaceAll("-", "").length === 9 &&
                    state.refNumber.length > 3 &&
                    (state.refNumber.length < 11 || state.refNumber.length > 15)
            );
        state.setSSN(state.ssn.toUpperCase());
    }, [state.ssn, state.refNumber]);

    function onCancel() {
        hooks.navigate("/auth/login");
    }

    async function onSubmit(actions: FormikHelpers<ForgotPasswordFields>) {
        if (state.securityType === "ForgotUsername") {
            submitForgotUsername(actions);
        } else if (state.securityType === "Enrollment") {
            submitEnrollment(actions);
        } else {
            submitForgotPassword(actions);
        }
    }

    async function submitForgotUsername(actions: FormikHelpers<ForgotPasswordFields>) {
        if (ForgotLoginNoMatch(actions)) {
            return;
        }

        api.forgotUsernamePassword("ForgotUsername")
            .then((res) => {
                if (res.data.mfaReturn?.messagingInfo?.accountId && res.data.mfaReturn?.messagingInfo?.accountId > 0) {
                    if (res.data.requestUsernamePasswordresponse?.username) {
                        state.setMfaOptions(res.data.mfaReturn.messagingInfo);
                        state.setSecurityType("ForgotUsername");
                        hooks.storeValue("username", Encrypt(res.data.requestUsernamePasswordresponse?.username));
                        hooks.navigate("/auth/show-username");
                    } else {
                        actions.resetForm();
                        let errorMessage =
                            res.data.requestUsernamePasswordresponse?.saveResult?.errors![0].errorMessage;
                        state.setErrorMessage(errorMessage ?? "Error retrieving username");
                        state.setShowErrorModal(true);
                    }
                } else {
                    actions.resetForm();
                    let errorMessage;
                    if (res.data.mfaReturn?.messagingInfo?.customDataClasSaveResult?.errors) {
                        errorMessage = res.data.mfaReturn.messagingInfo.customDataClasSaveResult.errors[0].errorMessage;
                        state.setErrorHeader("Online Account Temporarily Locked");
                        state.setErrorAction(() => exitEnroll);
                        state.setErrorButtonText("Close");
                    } else {
                        state.setErrorHeader("Information Doesn't Match");
                        errorMessage = res.data.requestUsernamePasswordresponse?.saveResult?.errors![0].errorMessage;
                    }
                    state.setErrorMessage(errorMessage ?? "Error");
                    state.setShowErrorModal(true);
                }
            })
            .catch((err) => {
                console.log("Forgot Username Error", err);
                hooks.navigate("/auth/login");
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }

    async function submitForgotPassword(actions: FormikHelpers<ForgotPasswordFields>) {
        if (ForgotLoginNoMatch(actions)) {
            return;
        }

        api.forgotUsernamePassword("ForgotPassword")
            .then(async (res) => {
                if (res.data.mfaReturn?.messagingInfo?.accountId && res.data.mfaReturn?.messagingInfo?.accountId > 0) {
                    if (
                        res.data.mfaReturn?.messagingInfo?.onlineUserId !== undefined &&
                        res.data.mfaReturn?.messagingInfo?.applicantHandle !== undefined
                    ) {
                        await api
                            .userConsentGet(
                                res.data.mfaReturn?.messagingInfo?.onlineUserId,
                                res.data.mfaReturn?.messagingInfo?.applicantHandle
                            )
                            .then(async (res2) => {
                                hooks.updateConsumerGuid(res2.data.consumerGuid);
                                hooks.updateJwt(res2.data.jwt);
                                hooks.syncOneTrustConsentProfile(res2.data.consumerGuid, res2.data.jwt);
                            });
                    }

                    state.setMfaOptions(res.data.mfaReturn.messagingInfo);
                    state.setSecurityType("ForgotPassword");
                    hooks.navigate("/auth/request-code");
                } else {
                    actions.resetForm();
                    let errorMessage;
                    if (res.data.mfaReturn?.messagingInfo?.customDataClasSaveResult?.errors) {
                        errorMessage = res.data.mfaReturn.messagingInfo.customDataClasSaveResult.errors[0].errorMessage;
                        state.setErrorHeader("Online Account Temporarily Locked");
                        state.setErrorAction(() => exitEnroll);
                        state.setErrorButtonText("Close");
                    } else {
                        state.setErrorHeader("Information Doesn't Match");
                        errorMessage = res.data.requestUsernamePasswordresponse?.saveResult?.errors![0].errorMessage;
                    }
                    state.setErrorMessage(errorMessage ?? "Error");
                    state.setShowErrorModal(true);
                }
            })
            .catch((err) => {
                console.log("Forgot Password Error", err);
                hooks.navigate("/auth/login");
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }

    function ForgotLoginNoMatch(actions: FormikHelpers<ForgotPasswordFields>): boolean {
        let invalidLength = 9 < state.refNumber.length && state.refNumber.length < 16;

        if (invalidLength) {
            state.setErrorHeader("Information Doesn't Match");
            state.setErrorMessage("The information entered does not match our records. Please try again.");
            state.setErrorAction(() => clearEnroll);
            state.setShowErrorModal(true);
            actions.setSubmitting(false);
            return true;
        }

        return false;
    }

    async function submitEnrollment(actions: FormikHelpers<ForgotPasswordFields>) {
        let sessionId = state.searchParams.get("id");

        let refLength = !(state.refNumber.length > 13 && state.refNumber.length < 16);

        if (sessionId && UUID.Valid(sessionId)) {
            if (!Regex.IsSSN(state.ssn) || !refLength) {
                state.setErrorHeader("Information Doesn't Match");
                state.setErrorMessage("The information entered does not match our records. Please try again.");
                state.setErrorAction(() => clearEnroll);
                state.setShowErrorModal(true);
                actions.setSubmitting(false);
                return;
            }

            api.validateIdentity(sessionId)
                .then((res) => {
                    hooks.navigate({
                        pathname: "/auth/register",
                        search: `?id=${sessionId}`
                    });
                })
                .catch((err: AxiosError) => {
                    switch (err.response?.status) {
                        case 422:
                            {
                                let errors = err.response?.data as ValidationErrors;
                                let errorField = Object.keys(errors.errors)[0];
                                state.setErrorMessage(errors.errors[errorField][0]);

                                if (EqualsIgnoreCase(errorField, "InvalidSession")) {
                                    state.setErrorHeader("Something Went Wrong");
                                    state.setErrorMessage("We were unable to process your request. Please try again.");
                                    state.setErrorAction(() => exitEnroll);
                                } else if (
                                    EqualsIgnoreCase(errorField, "InvalidSsn") ||
                                    EqualsIgnoreCase(errorField, "InvalidVerificationNumber") ||
                                    EqualsIgnoreCase(errorField, "SsnVerificationNumberMismatch") ||
                                    EqualsIgnoreCase(errorField, "RegistrationMismatch")
                                ) {
                                    state.setErrorHeader("Information Doesn't Match");
                                    state.setErrorMessage(
                                        "The information entered does not match our records. Please try again."
                                    );
                                    state.setErrorAction(() => clearEnroll);
                                } else if (EqualsIgnoreCase(errorField, "TooManyAttempts")) {
                                    state.setErrorHeader("Information Doesn't Match");
                                    state.setErrorMessage(
                                        `We were unable to process your request. For further assistance, contact Customer Service at ${HelpLine(["A", "B"].includes(state.ssn.slice(0, 0)))}.`
                                    );
                                    state.setErrorAction(() => exitEnroll);
                                } else if (EqualsIgnoreCase(errorField, "AccountAlreadyEnrolled")) {
                                    state.setErrorHeader("Already Enrolled");
                                    state.setErrorMessage(
                                        "This card is already enrolled in PREMIER Online. Please login or use the Forgot Password or Forgot Username options."
                                    );
                                    state.setErrorAction(() => exitEnroll);
                                } else if (EqualsIgnoreCase(errorField, "UnavailableAccount")) {
                                    state.setErrorHeader("Not Eligible");
                                    state.setErrorMessage(
                                        `This card is no longer eligible to be enrolled in PREMIER Online. For further assistance, contact Customer Service at ${HelpLine(["A", "B"].includes(state.ssn.slice(0, 0)))}.`
                                    );
                                    state.setErrorAction(() => exitEnroll);
                                }

                                state.setShowErrorModal(true);
                            }
                            break;
                    }
                })
                .finally(() => actions.setSubmitting(false));
        } else {
            state.setErrorHeader("Something Went Wrong");
            state.setErrorMessage("We were unable to process your request. Please try again.");
            state.setErrorAction(() => exitEnroll);
            state.setShowErrorModal(true);
            actions.setSubmitting(false);
        }
    }

    function clearEnroll() {
        state.setShowErrorModal(false);
        state.setSSN("");
        state.setRefNumber("");
    }

    function exitEnroll() {
        hooks.navigate("/auth/login");
    }

    return <ForgotLoginView {...state} onCancel={onCancel} onSubmit={onSubmit} />;
}
