import { ReactElement } from "react";
import { Accordion } from "react-bootstrap";
import { IntermediateWarningModal } from "../..";
import { DebugInfoFooter } from "../../devTools/debugInfo/debugInfo";
import { ButtonPro } from "../../generic";
import { Facebook, Instagram, Youtube } from "../../generic/icons";
import "./footer.scss";
import { FooterState } from "./footerViewModel";

type FooterViewProps = FooterState & {
    onSelect: Function;
    aboutUs: Function;
    contactUs: Function;
    myPremierCreditCard: Function;
    facebook: Function;
    youtube: Function;
    instagram: Function;
    privacyPolicy: Function;
    privacyNotice: Function;
    termsAndConditions: Function;
    accessibilityStatement: Function;
    premierCreditCardAgreements: Function;
    ccpa: Function;
    premierCreditProtectionContract: Function;
    cookieSettings: Function;
    premierCreditManager: Function;
};

function FooterView(props: FooterViewProps): ReactElement {
    const currentYear = new Date().getFullYear().toString();

    return (
        <div id={`footer${props.size}`}>
            <div id="footer-content">
                <Accordion
                    defaultActiveKey={"0"}
                    activeKey={props.iKey}
                    onSelect={(e) => {
                        props.onSelect(e);
                    }}
                    bsPrefix={`${props.acProps.accord}accordion`}
                    alwaysOpen={props.acProps.open}
                >
                    <Accordion.Item eventKey={props.acProps.keys[1]} bsPrefix={"footer-item"}>
                        <Accordion.Header bsPrefix={"footer-header"}>
                            <p className="footer-header-text">Company</p>
                        </Accordion.Header>
                        <Accordion.Body bsPrefix="footer-body">
                            <ButtonPro
                                className="footer-button"
                                content={"About Us"}
                                variant="tertiary"
                                onClick={() => props.aboutUs()}
                            />
                            <ButtonPro
                                className="footer-button"
                                content={"Contact"}
                                variant="tertiary"
                                onClick={() => props.contactUs()}
                            />
                            <ButtonPro
                                className="footer-button"
                                content={"My PREMIER Credit Card"}
                                variant="tertiary"
                                onClick={() => props.myPremierCreditCard()}
                            />
                            <div className="footer-icons">
                                <Facebook className="footer-icon" onClick={() => props.facebook()} />
                                <Instagram className="footer-icon" onClick={() => props.instagram()} />
                                <Youtube className="footer-icon" onClick={() => props.youtube()} />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={props.acProps.keys[0]} bsPrefix={"footer-item"}>
                        <Accordion.Header bsPrefix={"footer-header"}>
                            <p className="footer-header-text">Resources</p>
                        </Accordion.Header>
                        <Accordion.Body bsPrefix="footer-body">
                            {props.loggedIn ? (
                                <ButtonPro
                                    className="footer-button"
                                    content={"PREMIER Credit Manager"}
                                    variant="tertiary"
                                    onClick={() => props.premierCreditManager()}
                                />
                            ) : (
                                <></>
                            )}
                            <ButtonPro
                                className="footer-button"
                                content={"Current Credit Card Agreements"}
                                variant="tertiary"
                                onClick={() => props.premierCreditCardAgreements()}
                            />
                            <ButtonPro
                                className="footer-button"
                                content={"PREMIER Credit Protection Contract"}
                                variant="tertiary"
                                onClick={() => props.premierCreditProtectionContract()}
                            />
                            <ButtonPro
                                className="footer-button"
                                content={"Cookie Settings"}
                                variant="tertiary"
                                onClick={(e: React.SyntheticEvent) => {
                                    props.cookieSettings(e);
                                }}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={props.acProps.keys[2]} bsPrefix={"footer-item"}>
                        <Accordion.Header bsPrefix={"footer-header"}>
                            <p className="footer-header-text">Legal Notices</p>
                        </Accordion.Header>
                        <Accordion.Body bsPrefix="footer-body">
                            <ButtonPro
                                className="footer-button"
                                content={"Privacy Policy"}
                                variant="tertiary"
                                onClick={() => props.privacyPolicy()}
                            />
                            <ButtonPro
                                className="footer-button"
                                content={"Privacy Notice"}
                                variant="tertiary"
                                onClick={() => props.privacyNotice()}
                            />
                            <ButtonPro
                                className="footer-button"
                                content={"Terms of Use"}
                                variant="tertiary"
                                onClick={() => props.termsAndConditions()}
                            />
                            <ButtonPro
                                className="footer-button"
                                content={"Accessibility Statement"}
                                variant="tertiary"
                                onClick={() => props.accessibilityStatement()}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <p id="footer-copyright">
                © {currentYear} First PREMIER Bank and PREMIER Bankcard. All rights reserved. Member FDIC.
                <br />
                Notice to California Residents: This website collects personal information subject to the California
                Consumer Privacy Act.{" "}
                <a
                    id="learn-more-ccpa"
                    href=""
                    onClick={(e) => {
                        e.preventDefault();
                        props.ccpa();
                    }}
                >
                    Learn More
                </a>
            </p>
            <DebugInfoFooter />

            <IntermediateWarningModal
                show={props.showModal}
                setShow={props.setShowModal}
                dest={props.modalDest}
                cms={props.modalCms}
                openNewWindow={props.openNewWindow}
                setOpenNewWindow={props.setOpenNewWindow}
            />
        </div>
    );
}

export default FooterView;
