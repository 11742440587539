import { ReactElement } from "react";
import { ModalState } from "./modalViewModel";
import { ModalModelProps } from "./modalModel";
import "./modal.scss";

export function ModalView(props: ModalState & ModalModelProps): ReactElement {
    const classNameString: string = props.isSessionTimeout ? "modal-session-timeout" : "modal";

    return (
        <div id={classNameString}>
            <div id={`modal-content${props.size}`}>{props.children}</div>
        </div>
    );
}
