export const Env: string = String(process.env.REACT_APP_ENV);
export const Root: string = String(process.env.REACT_APP_ROOT);

export const IdentityClient: string = String(process.env.REACT_APP_AUTH_CLIENT_ID);
export const IdentitySecret: string = String(process.env.REACT_APP_AUTH_CLIENT_SECRET);

export const HashSecret: string = String(process.env.REACT_APP_HASH_SECRET);

export const AuthUrl: string = String(process.env.REACT_APP_AUTH_URL);
export const ApiBaseUrl: string = String(process.env.REACT_APP_APIBASEURL);
export const IdentityUrl: string = String(process.env.REACT_APP_IDENTITYURL);
export const MarketingSiteUrl: string = String(process.env.REACT_APP_MARKETINGSITEBASEURL);
export const PremierCreditManagerUrl: string = String(process.env.REACT_APP_PREMIERCREDITMANAGERURL);
export const ReCaptchaSiteKey: string = String(process.env.REACT_APP_RECAPTCHASITEKEY);
export const SessionTimeout: number = Number(process.env.REACT_APP_SESSIONTIMEOUT);
export const UseProxy: boolean = Boolean(process.env.REACT_APP_USEPROXY);
export const LogoutRedirectUrl: string = String(process.env.REACT_APP_LOGOUTLANDINGPAGE);

export const SwrveApiKey: string = String(process.env.REACT_APP_SWRVEAPIKEY);
export const SwrveAppId: number = Number(process.env.REACT_APP_SWRVEAPPID);
