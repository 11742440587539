import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import { SessionTimerContext } from "../../../contexts";
import { useBackgroundTick, useViewsize } from "../../../hooks";
import "./debugInfo.scss";

export function DebugInfoHeader(): React.ReactElement {
    const [today, setDate] = useState(new Date());
    const [visible, setVisible] = useState(true);
    const { sessionTimes, running } = useContext(SessionTimerContext);
    const { stop, restart } = useBackgroundTick();
    const size = useViewsize();
    const removeElement = () => {
        setVisible((prev) => !prev);
        stop();
    };
    const sessionTime = sessionTimes.endTime.diff(dayjs());

    useEffect(() => {
        const timer = setInterval(() => setDate(new Date()), 15000);
        return () => {
            clearInterval(timer);
        };
    });

    useEffect(() => {
        if (running) {
            restart();
        } else {
            stop();
        }
    }, [running]);

    return (
        <>
            {process.env.REACT_APP_ENV !== "Prod" && process.env.REACT_APP_VERSION && visible ? (
                <div id="debugInfoHeader" onClick={removeElement}>
                    <div>{new Date().toLocaleDateString()}</div>
                    <div>{today.toLocaleTimeString()}</div>
                    <div>{process.env.REACT_APP_ENV}</div>
                    <div>Front End Version = {process.env.REACT_APP_VERSION}</div>
                    <div>
                        {browserName} {browserVersion}
                    </div>
                    <div>
                        {size.width}x{size.height}
                    </div>
                    <div>Session {!running ? "Stopped" : msToTime(sessionTime)}</div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

function msToTime(s: number) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;

    return (hrs > 0 ? `${hrs}:` : "") + mins + ":" + (secs > 9 ? secs : `0${secs}`);
}

export function DebugInfoFooter(): React.ReactElement {
    const [today, setDate] = useState(new Date());
    const [visible, setVisible] = useState(true);

    const size = useViewsize();
    const removeElement = () => {
        setVisible((prev) => !prev);
    };

    useEffect(() => {
        const timer = setInterval(() => setDate(new Date()), 15000);
        return () => {
            clearInterval(timer);
        };
    });

    return (
        <>
            {process.env.REACT_APP_ENV !== "Prod" && process.env.REACT_APP_VERSION && visible ? (
                <div id="debugInfoFooter" onClick={removeElement}>
                    <div>{new Date().toLocaleDateString()}</div>
                    <div>{today.toLocaleTimeString()}</div>
                    <div>{process.env.REACT_APP_ENV}</div>
                    <div>Front End Version = {process.env.REACT_APP_VERSION}</div>
                    <div>
                        {browserName} {browserVersion}
                    </div>
                    <div>
                        {size.width}x{size.height}
                    </div>
                    <div className="break">{window.navigator.userAgent}</div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}
