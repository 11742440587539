import { ReactElement, useEffect } from "react";
import { isIOS } from "react-device-detect";
import { AppDownloadBannerView } from "./appDownloadBannerView";
import { useAppDownloadBannerViewModel } from "./appDownloadBannerViewModel";

export function AppDownloadBannerModel(): ReactElement {
    const { hooks, state, api } = useAppDownloadBannerViewModel();

    useEffect(() => {
        if (hooks.storage["intermediateWarning"] === undefined) {
            api.IntermediateWarning().then((res) => {
                state.setCms(res.data);
                hooks.storeValue("intermediateWarning", JSON.stringify(res.data));
            });
        } else {
            state.setCms(JSON.parse(hooks.storage["intermediateWarning"]));
        }
    }, []);

    useEffect(() => {
        if (isIOS) {
            state.setIntermediateDest("https://apps.apple.com/us/app/premier-credit-card/id1476775662");
        } else {
            state.setIntermediateDest(
                "https://play.google.com/store/apps/details?id=com.firstpremier.mypremiercreditcard.app"
            );
        }
    }, []);

    function callback() {
        hooks.storeValue("ShowAppBanner", false);
    }

    function close() {
        state.setShowBanner(false);
    }

    function getIt() {
        state.setShowIntermediate(true);
    }

    return <AppDownloadBannerView {...state} callback={callback} close={close} getIt={getIt} />;
}
