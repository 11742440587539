import { useRef } from "react";
import { IntermediateWarningProps } from "./intermediateWarningModel";

type IntermediateWarningViewModel = {
    state: IntermediateWarningState;
};

export type IntermediateWarningState = {
    headerText: string;
    bodyText: string;
};

export function useIntermediateWarningViewModel(props: IntermediateWarningProps): IntermediateWarningViewModel {
    const headerText = useRef(props.cms?.Name);
    const bodyText = useRef(props.cms?.BodyText?.replace("NOTICE: ", ""));

    return {
        state: {
            headerText: headerText.current,
            bodyText: bodyText.current
        }
    };
}
