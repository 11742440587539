import { ReactElement, useEffect } from "react";
import ReactDOM from "react-dom";
import { useModalViewModel } from "./modalViewModel";
import { ModalView } from "./modalView";

export type ModalProps = {
    show: boolean;
    isSessionTimeout?: boolean;
};

export function ModalProvider(props: ModalProps & ModalModelProps): ReactElement {
    if (props.show) {
        return ReactDOM.createPortal(
            <ModalModel>{props.children}</ModalModel>,
            document.getElementById("App") ?? document.createElement("App")
        );
    } else {
        return <></>;
    }
}

export type ModalModelProps = {
    children: ReactElement;
    isSessionTimeout?: boolean;
};

export function ModalModel(props: ModalModelProps): ReactElement {
    const { state } = useModalViewModel();

    useEffect(() => {
        if (state.size === "-mobile") {
            document.body.style.display = "flex";
            document.body.style.overflow = "hidden";
        }

        return () => {
            document.body.style.display = "contents";
            document.body.style.overflow = "auto";
        };
    }, []);

    useEffect(() => {
        if (state.size === "-mobile") {
            document.body.style.display = "flex";
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.display = "contents";
            document.body.style.overflow = "auto";
        }
    }, [state.size]);

    return <ModalView {...props} {...state} />;
}
