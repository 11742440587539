import { AxiosResponse } from "axios";
import { useContext, useEffect } from "react";
import { MultipleAccountPopupResponse, UserAccount } from ".";
import { UseGet, UsePost, useSessionStorage } from "../../../hooks";
import { IsNullOrEmpty } from "../../../utility";
import { useIdentityConnectContext } from "../../identityServer";
import { AccountContext } from "./accountContext";

export const useAccountContext = () => {
    const context = useContext(AccountContext);
    const client = useAccountClient();
    const [storage, , removeValue] = useSessionStorage(["accounts"]);

    useEffect(() => {
        if (!IsNullOrEmpty(storage["accounts"])) {
            context.setAccounts(JSON.parse(storage.accounts));
        }
    }, []);

    useEffect(() => {
        if (context.accounts && context.accountIndex >= context.accounts.length) {
            context.setAccountIndex(context.accounts.length - 1);
        }
    }, [context.accounts, context.accountIndex]);

    const clear = () => {
        context.setAccounts(undefined);
        removeValue(["accounts"]);
    };

    return {
        ...context,
        ...client,
        clear,
        accounts: context.accounts ?? (JSON.parse(storage.accounts ?? "[]") as UserAccount[])
    };
};

const useAccountClient = () => {
    const context = useContext(AccountContext);
    const { access_token } = useIdentityConnectContext();
    const [, storeValue] = useSessionStorage(["accounts"]);

    async function UserAccounts(token?: string): Promise<AxiosResponse<UserAccount[], any>> {
        context.setLoading(true);

        return await UseGet<UserAccount[]>("api/v1/account/useraccounts", token ?? access_token).then((x) => {
            let accounts = x.data.filter((a) => a.status !== "L" && a.status !== "U" && a.status !== "B");
            accounts = setAccents(accounts);
            context.setAccounts(accounts);
            storeValue("accounts", JSON.stringify(accounts));
            context.setLoading(false);
            return x;
        });
    }

    async function MAPopup(accountHandle: string): Promise<AxiosResponse<MultipleAccountPopupResponse, any>> {
        return await UseGet(`api/v1/account/multipleaccountpopup?accountHandle=${accountHandle}`, access_token);
    }

    async function MAPopupDisplayed(accountHandle: string, popupId: number): Promise<AxiosResponse<any, any>> {
        return await UsePost(
            `api/v1/account/multipleaccountpopup/wasdisplayed?accountHandle=${accountHandle}&multipleAccountPopUpId=${popupId}`,
            access_token
        );
    }

    return {
        UserAccounts,
        MAPopup,
        MAPopupDisplayed
    };
};

function setAccents(accounts: UserAccount[]): UserAccount[] {
    let apps = accounts.filter((x) => x.isApplicationStatus);
    let nonApps = accounts.filter((x) => !x.isApplicationStatus);

    apps.forEach((x) => (x.accent = -1));

    for (let i = 0; i < nonApps.length; i++) {
        nonApps[i].accent = i % 3;
    }

    let all = apps.concat(nonApps);

    return all;
}
