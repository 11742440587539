import { createContext, useContext, useEffect, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { Size } from "../types";

const mobile_width = 744;
const tablet_width = 1440;

interface IViewportProps {
    width: string | number;
    height: string | number;
}

const ViewportContext = createContext<IViewportProps>({
    width: "0px",
    height: "0px"
});

export const ViewportProvider = ({ children }: { children: any }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return <ViewportContext.Provider value={{ width, height }}>{children}</ViewportContext.Provider>;
};

export const useViewMobile = () => {
    const handleWindowResize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener("resize", handleWindowResize);

        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
};

export const useViewsize = () => {
    const { width, height } = useContext(ViewportContext);
    return { width, height };
};

export const useViewport = (tablet?: boolean, mobile?: boolean) => {
    const { width } = useViewsize();
    const [size, setSize] = useState("" as Size);

    useEffect(() => {
        if (((isMobile && !isTablet) || (width as number) < mobile_width) && mobile) {
            setSize("-mobile");
        } else if ((isTablet || (width as number) < tablet_width) && tablet) {
            setSize("-tablet");
        } else {
            setSize("");
        }
    }, [width, tablet, mobile]);

    return { size };
};
