import { AxiosResponse } from "axios";
import { CreditLineIncreaseGetResponse } from ".";
import { UseGet } from "../../../hooks";
import { useIdentityConnectContext } from "../../identityServer";

export const useCreditLineIncreaseContext = () => {
    const client = useCreditLineIncreaseClient();

    return { ...client };
};

const useCreditLineIncreaseClient = () => {
    const { access_token } = useIdentityConnectContext();

    function Get(accountHandle: string): Promise<AxiosResponse<CreditLineIncreaseGetResponse, any>> {
        return UseGet(`api/v1/creditlineincrease?accountHandle=${accountHandle}`, access_token);
    }

    return { Get };
};
