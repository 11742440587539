export const defaultFormatChars = {
    "9": "[0-9]",
    a: "[A-Za-z]",
    "*": "[A-Za-z0-9]"
};

export const privateFormatChars = {
    "9": "[0-9•]",
    a: "[A-Za-z•]",
    "*": "[A-Za-z0-9•]"
};

export const defaultMaskChar = null as string | null;
