import { AxiosResponse } from "axios";
import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
    ChangePasswordRequest,
    ElectronicDeliveryPreferences,
    OnlineActions,
    UserAccount,
    useAccountContext,
    useDeliveryPreferencesContext,
    useDigitalActionsContext,
    usePasswordContext
} from "../../../api/backend";
import { useDeepLinkContext, useMfaContext, useSwrveContext } from "../../../contexts";
import { SecurityType } from "../../../contexts/mfaContext";
import { useLocalStorage, useViewport } from "../../../hooks";
import { Size } from "../../../types";

type NewPasswordViewModel = {
    hooks: NewPasswordHooks;
    state: NewPasswordState;
    api: NewPasswordApi;
};

type NewPasswordHooks = {
    navigate: NavigateFunction;
    initializeSwrve: (applicantHandle: string) => void;
    removeLocal: (name: string) => void;
};

export type NewPasswordState = {
    size: Size;
    securityType: SecurityType | undefined;
    setSecurityType: (value: SecurityType) => void;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    canContinue: boolean;
    setCanContinue: React.Dispatch<React.SetStateAction<boolean>>;
    showSplash: boolean;
    setShowSplash: React.Dispatch<React.SetStateAction<boolean>>;
    passwordTouched: boolean;
    setPasswordTouched: React.Dispatch<React.SetStateAction<boolean>>;
    confirmTouched: boolean;
    setConfirmTouched: React.Dispatch<React.SetStateAction<boolean>>;
    deepLink: string;
    header: string;
    subtitle: string;
    continueText: string;
    isTempPassword: boolean;
    passwordText: string;
    passwordPlaceHolder: string;
    confirmPasswordText: string;
    confirmPasswordPlaceHolder: string;
};

type NewPasswordApi = {
    userAccounts: () => Promise<AxiosResponse<UserAccount[], any>>;
    digitalActions: (accounts: UserAccount[]) => Promise<AxiosResponse<OnlineActions[], any>>;
    getPreferences: () => Promise<AxiosResponse<ElectronicDeliveryPreferences[], any>>;
    changePassword: (request: ChangePasswordRequest) => Promise<AxiosResponse<UserAccount[], any>>;
};

const localKeys = ["TrustedDeviceAuthentication"];

export function useNewPasswordViewModel(): NewPasswordViewModel {
    const navigate = useNavigate();
    const { size } = useViewport(false, true);
    const { Change } = usePasswordContext();
    const { securityType, setSecurityType } = useMfaContext();
    const { UserAccounts } = useAccountContext();
    const { Get: getActions } = useDigitalActionsContext();
    const { Get: getPreferences } = useDeliveryPreferencesContext();
    const { initializeSwrve } = useSwrveContext();
    const [showModal, setShowModal] = useState(false);
    const [canContinue, setCanContinue] = useState(false);
    const [showSplash, setShowSplash] = useState(false);
    const [passwordTouched, setPasswordTouched] = useState(false);
    const [confirmTouched, setConfirmTouched] = useState(false);
    const { deepLink } = useDeepLinkContext();
    const constants = getConstants(securityType);
    const [, , removeLocal] = useLocalStorage(localKeys);

    async function userAccounts(): Promise<AxiosResponse<UserAccount[], any>> {
        return await UserAccounts();
    }

    async function changePassword(request: ChangePasswordRequest): Promise<AxiosResponse<UserAccount[], any>> {
        return Change(request);
    }

    async function digitalActions(accounts: UserAccount[]): Promise<AxiosResponse<OnlineActions[], any>> {
        return getActions(accounts[0].accountHandle);
    }

    return {
        hooks: {
            navigate,
            initializeSwrve,
            removeLocal
        },
        state: {
            size,
            securityType,
            setSecurityType,
            showModal,
            setShowModal,
            canContinue,
            setCanContinue,
            showSplash,
            setShowSplash,
            passwordTouched,
            setPasswordTouched,
            confirmTouched,
            setConfirmTouched,
            deepLink,
            ...constants
        },
        api: {
            userAccounts,
            getPreferences,
            changePassword,
            digitalActions
        }
    };
}

function getConstants(type: SecurityType | undefined): {
    header: string;
    subtitle: string;
    continueText: string;
    isTempPassword: boolean;
    passwordText: string;
    passwordPlaceHolder: string;
    confirmPasswordText: string;
    confirmPasswordPlaceHolder: string;
} {
    let header = "Create a New Password";
    let subtitle = "Step 3 of 3";
    let continueText = "Create & Log In";
    let isTempPassword = false;
    let passwordText = "Password";
    let passwordPlaceHolder = "Enter password";
    let confirmPasswordText = "Confirm password";
    let confirmPasswordPlaceHolder = "Re-enter password";

    if (type === "TempPassword") {
        header = "Create New Password";
        subtitle =
            "Enter the temporary password you received via email and your new desired password in the fields below.";
        continueText = "Create New Password";
        isTempPassword = true;
        passwordText = "New Password";
        passwordPlaceHolder = "Enter new password";
        confirmPasswordText = "Confirm New Password";
        confirmPasswordPlaceHolder = "Re-enter new password";
    }

    return {
        header,
        subtitle,
        continueText,
        isTempPassword,
        passwordText,
        passwordPlaceHolder,
        confirmPasswordText,
        confirmPasswordPlaceHolder
    };
}
