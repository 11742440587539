import { ReactElement } from "react";
import { ActivityState } from "./activityViewModel";
import { ActivityRow } from "./activityRow/activityRow";
import { UserAccount } from "../../../api/backend";

export function CreateRows(state: ActivityState, account: UserAccount): ReactElement[] {
    let newRows = [] as ReactElement[];

    for (let i = 0; i < state.displayedHistory.length; i++) {
        newRows.push(
            <ActivityRow
                key={`displayedHistory[i].ReferenceNumber.toString()${i}`}
                index={i}
                account={account}
                activity={state.displayedHistory[i]}
            />
        );
    }

    return newRows;
}
