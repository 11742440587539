import { ReactElement, createContext, useEffect, useState } from "react";
import { isAndroid, isIOS, isMobile, isTablet } from "react-device-detect";
import { useSessionStorage } from "../hooks";
import { Children } from "../types";
import { NotImplemented } from "../utility";

export type AppDownloadBannerContextProps = {
    showBanner: boolean;
    setShowBanner: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AppDownloadBannerContext = createContext<AppDownloadBannerContextProps>({
    showBanner: false,
    setShowBanner: NotImplemented
});

const useAppDownloadBannerContextProps = (): AppDownloadBannerContextProps => {
    const [storage] = useSessionStorage(["ShowAppBanner"]);
    const [showBanner, setShowBanner] = useState((storage["ShowAppBanner"] as boolean) ?? false);

    return {
        showBanner,
        setShowBanner
    };
};

export function AppDownloadBanner(props: Children): ReactElement {
    const contextProps = useAppDownloadBannerContextProps();
    const [session, storeSession] = useSessionStorage(["ShowAppBanner"]);

    useEffect(() => {
        if ([undefined, "true"].includes(session["ShowAppBanner"])) {
            if ((isIOS || isAndroid) && (isMobile || isTablet)) {
                contextProps.setShowBanner(true);
                storeSession("ShowAppBanner", true);
            } else {
                contextProps.setShowBanner(false);
                storeSession("ShowAppBanner", false);
            }
        } else {
            contextProps.setShowBanner(false);
        }
    }, []);

    useEffect(() => {
        storeSession("ShowAppBanner", contextProps.showBanner);
    }, [contextProps.showBanner]);

    return (
        <>
            <AppDownloadBannerContext.Provider value={contextProps}>{props.children}</AppDownloadBannerContext.Provider>
        </>
    );
}
