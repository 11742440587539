import { useEffect, useState } from "react";
import { AppSettings, ConsumerAlertCms, useCms, useSettingsContext } from "../../../api/backend";
import { useSessionStorage, useViewsize } from "../../../hooks";

type ProminentAlertViewModel = {
    hooks: ProminentAlertHooks;
    state: ProminentAlertState;
};

type ProminentAlertHooks = {
    storeValue: (name: string, value: any) => void;
};

export type ProminentAlertState = {
    appSettings: AppSettings;
    storage: { [x: string]: any };
    alert: ConsumerAlertCms | undefined;
    dismissed: boolean;
    setDismissed: React.Dispatch<React.SetStateAction<boolean>>;
    truncated: boolean;
    more: boolean;
    setMore: React.Dispatch<React.SetStateAction<boolean>>;
};

const storageKeys = ["prominentAlert"];

export function useProminentAlertViewModel(): ProminentAlertViewModel {
    const { width } = useViewsize();
    const { ConsumerAlert } = useCms();
    const { appSettings } = useSettingsContext();
    const [alert, setAlert] = useState<ConsumerAlertCms>();
    const [storage, storeValue] = useSessionStorage(storageKeys);
    const [dismissed, setDismissed] = useState(storage["prominentAlert"] ?? false);
    const [truncated, setTruncated] = useState(false);
    const [more, setMore] = useState(false);

    useEffect(() => {
        ConsumerAlert().then((x) => setAlert(x.data));
    }, []);

    useEffect(() => {
        checkTruncated();
    }, [width, alert]);

    const checkTruncated = () => {
        let ele = document.getElementById("prominentAlert-body") as HTMLSpanElement;
        if (ele && !more) {
            setTruncated(ele.parentElement!.scrollHeight - 2 > ele.parentElement!.clientHeight);
        }
    };

    return {
        hooks: {
            storeValue
        },
        state: {
            appSettings,
            storage,
            alert,
            dismissed,
            setDismissed,
            truncated,
            more,
            setMore
        }
    };
}
