import { ReactElement, useEffect } from "react";
import { isIOS, isMobile } from "react-device-detect";
import { StatementDetails, UserAccount, useSettingsContext } from "../../../../api/backend";
import { StatementsRowView } from "./statementsRowView";
import { useStatementsRowViewModel } from "./statementsRowViewModel";

export type StatementsRowProps = {
    index: number;
    account: UserAccount;
    statement: StatementDetails;
};

export function StatementsRow(props: StatementsRowProps): ReactElement {
    const { hooks, state } = useStatementsRowViewModel();
    const { appSettings } = useSettingsContext();
    const isEMessengerDown: boolean = appSettings?.featureFlags?.isEMessengerDown;

    useEffect(() => {
        state.setExpanded(props.index === state.curIndex);
    }, [state.curIndex]);

    function downloadPDF() {
        if (!isEMessengerDown) {
            state.setShowLoading(true);
            hooks
                .getStatement(props.account.accountHandle, props.statement.statementId)
                .then((res) => {
                    var byteCharacters = atob(res.data.pdfStatement);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var file = new Blob([byteArray], { type: "application/pdf;base64" });
                    var fileURL = URL.createObjectURL(file);
                    const downloadLink = document.createElement("a");
                    let statementDate = new Date(props.statement.statementDate);
                    var month = statementDate.getMonth() + 1;
                    var day = statementDate.getDate();
                    var year = statementDate.getFullYear();
                    const fileName = `${year}-${month}-${day} Statement.pdf`;
                    downloadLink.href = fileURL;
                    downloadLink.download = fileName;
                    downloadLink.click();
                })
                .finally(() => {
                    state.setShowLoading(false);
                });
        }
    }

    function openPDF() {
        if (!isEMessengerDown) {
            if (isIOS && isMobile) {
                downloadPDF();
            } else {
                state.setShowLoading(true);
                hooks
                    .getStatement(props.account.accountHandle, props.statement.statementId)
                    .then((res) => {
                        var byteCharacters = atob(res.data.pdfStatement);
                        var byteNumbers = new Array(byteCharacters.length);
                        for (var i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        var byteArray = new Uint8Array(byteNumbers);
                        var file = new Blob([byteArray], { type: "application/pdf;base64" });
                        var fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    })
                    .finally(() => {
                        state.setShowLoading(false);
                    });
            }
        }
    }

    return (
        <StatementsRowView
            {...props}
            {...state}
            {...hooks}
            downloadPDF={downloadPDF}
            openPDF={openPDF}
            newRowExpanded={hooks.newRowExpanded}
        />
    );
}
