import { useContext } from "react";
import { browserName, browserVersion } from "react-device-detect";
import { NavigateFunction, useLoaderData, useNavigate } from "react-router-dom";
import { useIdentityConnectContext } from "../../../api/identityServer";
import { BrowserSupportContext } from "../../../contexts/browserSupportContext";
import { useSessionStorage, useViewport } from "../../../hooks";
import { Size } from "../../../types";
import { BrowserCompatibilityData } from "./browserCompatibilityLoader";

type BrowserCompatibilityViewModel = {
    hooks: BrowserCompatibilityHooks;
    state: BrowserCompatibilityState;
};

type BrowserCompatibilityHooks = {
    navigate: NavigateFunction;
    storeValue: (name: string, value: any) => void;
};

export type BrowserCompatibilityState = {
    size: Size;
    access_token: string;
    headerText: string;
    bodyText: string;
    current: boolean;
    supported: boolean;
    acknowledgement: boolean;
    setAcknowledgement: React.Dispatch<React.SetStateAction<boolean>>;
};

const storageKeys = ["browserAcknowledgement"];

export function useBrowserCompatibilityViewModel(): BrowserCompatibilityViewModel {
    const navigate = useNavigate();
    const { size } = useViewport(false, true);
    const data = useLoaderData() as BrowserCompatibilityData;
    const context = useContext(BrowserSupportContext);
    const { access_token } = useIdentityConnectContext();
    const [, storeValue] = useSessionStorage(storageKeys);
    const { headerText, bodyText } = getTexts(data);

    return {
        hooks: { navigate, storeValue },
        state: { size, access_token, headerText, bodyText, ...data, ...context }
    };
}

function getTexts(data: BrowserCompatibilityData): { headerText: string; bodyText: string } {
    const headerText = getHeaderText(data);
    const bodyText = getBodyText(data);

    return { headerText, bodyText };
}

function getHeaderText(data: BrowserCompatibilityData) {
    if (!data.supported) {
        return "Browser Not Supported";
    }
    if (!data.current) {
        return "Browser Out of Date";
    }

    return "Browser Supported";
}

function getBodyText(data: BrowserCompatibilityData) {
    if (!data.supported) {
        return data.cms.FooterText;
    }
    if (!data.current) {
        return data.cms.BodyText;
    }

    return `${browserName} version ${browserVersion} is currently supported!\n Please Refresh your Page!`;
}
