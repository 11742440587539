import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserAccount, useAccountContext, useDigitalActionsContext, useSettingsContext } from "../../../api/backend";
import { CardNavContext } from "../../../contexts";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";

type ActivitySummaryViewModel = {
    state: ActivitySummaryState;
};

export type ActivitySummaryState = {
    size: Size;
    urlId: number;
    accounts: UserAccount[];
    accountIndex: number;
    setAccountIndex: React.Dispatch<React.SetStateAction<number>>;
    setShowNav: React.Dispatch<React.SetStateAction<boolean>>;
    allowPaperless: boolean;
    isEMessengerDown: boolean;
};

export function useActivitySummaryViewModel(): ActivitySummaryViewModel {
    const { size } = useViewport(false, true);
    const { id } = useParams();
    const { accounts, accountIndex, setAccountIndex } = useAccountContext();
    const { setShowNav } = useContext(CardNavContext);
    const { actionAllowed } = useDigitalActionsContext();
    const [allowPaperless, setAllowPaperless] = useState(true);
    const { appSettings } = useSettingsContext();
    const isEMessengerDown: boolean = appSettings?.featureFlags?.isEMessengerDown;

    useEffect(() => {
        let accountHandle = accounts![Number(id)].accountHandle;
        setAllowPaperless(
            actionAllowed("eLetterEnroll", accountHandle) || actionAllowed("eStatementEnroll", accountHandle)
        );
    }, [accountIndex]);

    return {
        state: {
            size,
            urlId: Number(id),
            accounts: accounts!,
            accountIndex,
            setAccountIndex,
            setShowNav,
            allowPaperless,
            isEMessengerDown
        }
    };
}
