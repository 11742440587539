import { ReactElement } from "react";
import { AddPaymentAccount, PaymentAccountsDelete } from "../..";
import { AsyncElement, ButtonPro } from "../../generic";
import { Add, ArrowDown, ArrowUp } from "../../generic/icons";
import "./paymentAccount.scss";
import { PaymentAccountState } from "./paymentAccountViewModel";
import { PaymentAccountsDeleteSuccess } from "./paymentAccountsDeleteSuccess/paymentAccountsDeleteSuccess";

type PaymentAccountViewProps = PaymentAccountState & {
    onAdd: Function;
};

export function PaymentAccountView(props: PaymentAccountViewProps): ReactElement {
    return (
        <div className="paymentaccount">
            <div className="paymentaccount-header">
                <h5 className="paymentaccount-header-text">Manage Payment Accounts</h5>
                <ButtonPro
                    btnSize="sm"
                    className="paymentaccount-header-button"
                    variant="neutral"
                    onClick={() => props.onAdd()}
                >
                    <Add className="paymentaccount-header-add-icon" svgClass="icon-size-lg" />
                    <h5 className="paymentaccount-header-add">Add</h5>
                </ButtonPro>
            </div>
            <AsyncElement loading={props.loading}>
                <div className="paymentaccounts-list">
                    {props.rows.length > 0 ? (
                        props.rows
                    ) : (
                        <div className="paymentaccount-body-none-enrolled">
                            <h5>No payment accounts enrolled</h5>
                        </div>
                    )}
                </div>
            </AsyncElement>
            <div className="paymentaccount-footer">
                {props.paymentAccounts.length > 3 ? (
                    <ButtonPro
                        variant="neutral"
                        className="paymentaccount-viewmore"
                        unblock
                        onClick={() => props.setViewMore(!props.viewMore)}
                    >
                        <h6>View {!props.viewMore ? "More" : "Less"}</h6>
                        {!props.viewMore ? <ArrowDown /> : <ArrowUp />}
                    </ButtonPro>
                ) : (
                    <p>&nbsp;</p>
                )}
            </div>

            <PaymentAccountsDelete
                show={props.showDelete}
                setShow={props.setShowDelete}
                setShowSuccess={props.setShowSuccess}
                deletedAccount={props.deletedAccount}
                setDeletedAccount={props.setDeletedAccount}
            />

            <PaymentAccountsDeleteSuccess
                show={props.showSuccess}
                setShow={props.setShowSuccess}
                deletedAccount={props.deletedAccount}
                setDeletedAccount={props.setDeletedAccount}
            ></PaymentAccountsDeleteSuccess>

            <AddPaymentAccount showEnter={props.showAdd} setShowEnter={props.setShowAdd} />
        </div>
    );
}
