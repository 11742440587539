import { AxiosResponse } from "axios";
import { LostStolenTransactionsResponse, TransactionDetails } from ".";
import { UseGet } from "../../../hooks";
import { useIdentityConnectContext } from "../../identityServer";

export const useTransactionsContext = () => {
    const client = useTransactionsClient();

    return { ...client };
};

const useTransactionsClient = () => {
    const { access_token } = useIdentityConnectContext();

    async function Recent(accountHandle: string): Promise<AxiosResponse<TransactionDetails[], any>> {
        return await UseGet(`api/v1/transactions/recent?accountHandle=${accountHandle}`, access_token);
    }

    async function All(accountHandle: string): Promise<AxiosResponse<TransactionDetails[], any>> {
        return await UseGet(`api/v1/transactions/all?accountHandle=${accountHandle}`, access_token);
    }

    async function LostStolen(accountHandle: string): Promise<AxiosResponse<LostStolenTransactionsResponse[], any>> {
        return await UseGet(`api/v1/transactions/loststolen?accountHandle=${accountHandle}`, access_token);
    }

    return { Recent, All, LostStolen };
};
