import { AxiosResponse } from "axios";
import { BankAccountNameResponse } from ".";
import { UseGet } from "../../../hooks";
import { useIdentityConnectContext } from "../../identityServer";

export const useBankAccountNameContext = () => {
    const client = useBankAccountNameClient();

    return { ...client };
};

const useBankAccountNameClient = () => {
    const { access_token } = useIdentityConnectContext();

    async function Get(abaNumber: string): Promise<AxiosResponse<BankAccountNameResponse, any>> {
        return await UseGet(`api/v1/bankaccountname?abaNumber=${abaNumber}`, access_token);
    }

    return {
        Get
    };
};
