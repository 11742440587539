import { AxiosResponse } from "axios";
import { DocumentContents, DocumentDetails } from ".";
import { UseGet } from "../../../hooks";
import { useIdentityConnectContext } from "../../identityServer";

export const useDocumentsContext = () => {
    const client = useDocumentsClient();

    return { ...client };
};

const useDocumentsClient = () => {
    const { access_token } = useIdentityConnectContext();

    async function Get(accountHandle: string): Promise<AxiosResponse<DocumentDetails[], any>> {
        return await UseGet(`api/v1/documents?accountHandle=${accountHandle}`, access_token);
    }

    async function GetById(documentId: number): Promise<AxiosResponse<DocumentContents, any>> {
        return await UseGet(`api/v1/documents/${documentId}`, access_token);
    }

    return { Get, GetById };
};
