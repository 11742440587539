import { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
    PCPAccounts,
    PremierCreditProtectionCMS,
    PremierCreditProtectionResponse,
    UserAccount,
    useAccountContext,
    useCms,
    useDigitalActionsContext,
    usePCPContext
} from "../../../../api/backend";
import { CardNavContext } from "../../../../contexts";
import { useViewport } from "../../../../hooks";
import { MarketingSiteUrl } from "../../../../res/react-env-variables";
import { Size } from "../../../../types";
import { EqualsIgnoreCase, HelpLine } from "../../../../utility";
import { GenericModalProps } from "../../../generic";

export type PCPEnrollmentStatusViewModel = {
    state: PCPEnrollmentStatusState;
    api: PCPEnrollmentStatusAPI;
    hooks: PCPEnrollmentStatusHooks;
};

export type PCPEnrollmentStatusState = {
    premierCreditProtectionEnrollment: PremierCreditProtectionResponse | undefined;
    setPremierCreditProtectionEnrollment: React.Dispatch<
        React.SetStateAction<PremierCreditProtectionResponse | undefined>
    >;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    accounts: UserAccount[] | undefined;
    showIneligibleAlert: boolean;
    setShowIneligibleAlert: React.Dispatch<React.SetStateAction<boolean>>;
    showUnenrollButton: boolean;
    setShowUnenrollButton: React.Dispatch<React.SetStateAction<boolean>>;
    enrollMode: boolean;
    setEnrollMode: React.Dispatch<React.SetStateAction<boolean>>;
    touched: boolean;
    setTouched: React.Dispatch<React.SetStateAction<boolean>>;
    enrollmentArray: EnrollmentStatusArray[] | undefined;
    setEnrollmentArray: React.Dispatch<React.SetStateAction<EnrollmentStatusArray[] | undefined>>;
    allAccountsEnrollerOrIneligible: boolean;
    setAllAccountsEnrollerOrIneligible: React.Dispatch<React.SetStateAction<boolean>>;
    pcpCMS: PremierCreditProtectionCMS | undefined;
    setpcpCMS: React.Dispatch<React.SetStateAction<PremierCreditProtectionCMS | undefined>>;
    overSixtyTwoModal: GenericModalProps;
    showOverSixtyTwoModal: boolean;
    setShowOverSixtyTwoModal: React.Dispatch<React.SetStateAction<boolean>>;
    showUnenrollModal: boolean;
    setShowUnenrollModal: React.Dispatch<React.SetStateAction<boolean>>;
    UnenrollModal: GenericModalProps;
    showEnrollmentModal: boolean;
    setShowEnrollmentModal: React.Dispatch<React.SetStateAction<boolean>>;
    successModal: GenericModalProps;
    showSuccessModal: boolean;
    setShowSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
    accountIndex: number;
    size: Size;
};

export type PCPEnrollmentStatusAPI = {
    getPCPEnrollmentStatus: () => Promise<AxiosResponse<PremierCreditProtectionResponse, any>>;
    navigateToPCP: () => void;
    getPremierCreditProtectionCMS: () => Promise<AxiosResponse<PremierCreditProtectionCMS, any>>;
    navigateToActivationForm: () => void;
    navigateToProtectionContract: () => void;
    loadPCPStatus: () => void;
};
type PCPEnrollmentStatusHooks = {
    navigate: NavigateFunction;
    actionAllowed: (option: string, accountHandle?: string | undefined) => boolean;
};
export type EnrollmentStatusArray = {
    last4: string;
    checked: boolean;
    accent: number;
    accountHandle: string;
    enrolled: boolean;
};

export function usePCPEnrollmentStatusViewModel(): PCPEnrollmentStatusViewModel {
    const { accounts, accountIndex } = useAccountContext();
    const { Get } = usePCPContext();
    const [loading, setLoading] = useState(true);
    const [showIneligibleAlert, setShowIneligibleAlert] = useState(false);
    const [showUnenrollButton, setShowUnenrollButton] = useState(false);
    const [enrollMode, setEnrollMode] = useState(false);
    const [touched, setTouched] = useState(false);
    const [enrollmentArray, setEnrollmentArray] = useState<EnrollmentStatusArray[]>();
    const [allAccountsEnrollerOrIneligible, setAllAccountsEnrollerOrIneligible] = useState(false);
    const { PremierCreditProtection } = useCms();
    const [pcpCMS, setpcpCMS] = useState<PremierCreditProtectionCMS>();
    const [showOverSixtyTwoModal, setShowOverSixtyTwoModal] = useState(false);
    const [showUnenrollModal, setShowUnenrollModal] = useState(false);
    const { setShowNav } = useContext(CardNavContext);
    const [showEnrollmentModal, setShowEnrollmentModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const { actionAllowed } = useDigitalActionsContext();
    const { size } = useViewport(false, true);

    useEffect(() => {
        setShowNav(false);
    }, []);

    const overSixtyTwoModal = {
        headerText: "Confirm Enrollment",
        bodyText: `<p>If you are not currently employed, such as if you are retired, you may not be eligible for Disability, Unpaid Family Leave or Involuntary Unemployment protection. The cost of the program will not change if you become ineligible for some of the protection.</p>
            </br>
            <p>If you feel this coverage will no longer apply to you, select Cancel.</p>
            </br>
            <p>If you feel this coverage may still benefit you, select Continue.</p>`,
        primaryButton: {
            text: "Continue",
            action: () => {
                setShowOverSixtyTwoModal(false);
                setShowEnrollmentModal(true);
            }
        },
        secondaryButton: {
            text: "Cancel",
            variant: "neutral",
            action: () => {
                setShowOverSixtyTwoModal(false);
                setEnrollMode(false);
            }
        }
    } as GenericModalProps;

    const UnenrollModal = {
        headerText: "Unenroll From PREMIER Credit Protection",
        bodyText: `<p>Either you or PREMIER Bankcard may voluntarily terminate the contract at any time by sending written notice or calling the other party.</p>
            </br>
            <p>You may call ${HelpLine(accounts[accountIndex].isCorpCard)} to terminate your enrollment in PREMIER Credit Protection. Alternatively, you may send your termination notice to the following address:</p>
            </br>
            <h5>Benefit Administrator</h5>
            <h5>PO Box 5506</h5>
            <h5>Sioux Falls, SD 57117-5506</h5>`,

        primaryButton: {
            text: "Close",
            action: () => {
                setShowUnenrollModal(false);
            }
        }
    } as GenericModalProps;

    const successModal = {
        isBranded: true,
        headerText: "You have successfully enrolled in PREMIER Credit Protection!",
        bodyText: `<p>You will be receiving the PREMIER Credit Protection contract in the mail within 7 to 10 days.</p>
                </br>
                <p>Remember, PREMIER Credit Protection protects against life's unexpected events like Involuntary Unemployment, Disability, Loss of Life, Unpaid Family Leave and Hospitalization. Please refer to the PREMIER Credit Protection contract to review all qualification requirements.</p>
                </br>
                <p>Involuntary Unemployment, Disability and Unpaid Family Leave may cover your monthly minimum payment, as of the date the protected event began, for up to twelve months. If any of these life's events happen to you, simply call our benefit administrator at ${HelpLine(accounts[accountIndex].isCorpCard)} to activate a benefit.</p>`,
        primaryButton: {
            text: "Close",
            action: () => {
                setShowSuccessModal(false);
                loadPCPStatus();
                setEnrollMode(false);
            }
        }
    } as GenericModalProps;

    const navigate = useNavigate();

    function loadPCPStatus() {
        setLoading(true);
        getPCPEnrollmentStatus()
            .then((res) => {
                let newPCPAccounts: PCPAccounts[] = [];
                let enrollmnetArray: EnrollmentStatusArray[] = [];
                accounts!.forEach((account) => {
                    let pcpAccount = res.data.accounts.find((x) =>
                        EqualsIgnoreCase(x.accountHandle, account.accountHandle)
                    );
                    if (pcpAccount !== undefined) {
                        newPCPAccounts.push(pcpAccount);
                    }
                });
                let allEnrolledOrIneligible = true;
                newPCPAccounts.forEach((PCPAccount, index) => {
                    if (PCPAccount.enrolled) {
                        setShowUnenrollButton(true);
                    } else if (!PCPAccount.eligible) {
                        setShowIneligibleAlert(true);
                    } else {
                        allEnrolledOrIneligible = false;
                    }
                    enrollmnetArray.push({
                        checked: PCPAccount.enrolled,
                        last4: accounts![index].maskedAccountNumber.slice(-4),
                        accent: accounts![index].accent,
                        accountHandle: accounts![index].accountHandle,
                        enrolled: PCPAccount.enrolled
                    });
                });
                setAllAccountsEnrollerOrIneligible(allEnrolledOrIneligible);
                setEnrollmentArray(enrollmnetArray);
                setPremierCreditProtectionEnrollment({
                    accounts: newPCPAccounts,
                    overSixtyTwo: res.data.overSixtyTwo
                });
                getPremierCreditProtectionCMS()
                    .then((res) => {
                        setpcpCMS(res.data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function navigateToPCP() {
        navigate("/account/premier-credit-protection");
    }

    function navigateToActivationForm() {
        window.open(window.location.origin + "/home/PREMIER-credit-protection-benefit-activation-form", "_blank");
    }

    function navigateToProtectionContract() {
        window.open(
            `${MarketingSiteUrl}media/abvfi1to/premier_credit_protection_contract.pdf?mkt=306&submkt=4042`,
            "_blank"
        );
    }

    async function getPremierCreditProtectionCMS(): Promise<AxiosResponse<PremierCreditProtectionCMS, any>> {
        return PremierCreditProtection();
    }

    const [premierCreditProtectionEnrollment, setPremierCreditProtectionEnrollment] =
        useState<PremierCreditProtectionResponse>();

    function getPCPEnrollmentStatus(): Promise<AxiosResponse<PremierCreditProtectionResponse, any>> {
        return Get(accounts![0].applicantHandle);
    }

    return {
        state: {
            premierCreditProtectionEnrollment,
            setPremierCreditProtectionEnrollment,
            loading,
            setLoading,
            accounts,
            showIneligibleAlert,
            setShowIneligibleAlert,
            setShowUnenrollButton,
            showUnenrollButton,
            enrollMode,
            setEnrollMode,
            setTouched,
            touched,
            enrollmentArray,
            setEnrollmentArray,
            setAllAccountsEnrollerOrIneligible,
            allAccountsEnrollerOrIneligible,
            pcpCMS,
            setpcpCMS,
            overSixtyTwoModal,
            setShowOverSixtyTwoModal,
            showOverSixtyTwoModal,
            setShowUnenrollModal,
            showUnenrollModal,
            UnenrollModal,
            showEnrollmentModal,
            setShowEnrollmentModal,
            successModal,
            showSuccessModal,
            setShowSuccessModal,
            accountIndex,
            size
        },
        api: {
            getPCPEnrollmentStatus,
            navigateToPCP,
            getPremierCreditProtectionCMS,
            navigateToActivationForm,
            navigateToProtectionContract,
            loadPCPStatus
        },
        hooks: { navigate, actionAllowed }
    };
}
