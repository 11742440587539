import dayjs from "dayjs";
import { ReactElement, useEffect, useState } from "react";
import { Columns } from "react-csv-downloader/dist/esm/lib/csv";
import { TransactionDetails, useAccountContext } from "../../../../api/backend";
import { DownloadDetails, OFXExporter } from "../../../../utility/ofxExporter";
import { ActivityDownloadView } from "./activityDownloadView";

type ActivityDownloadProps = {
    filteredHistory: TransactionDetails[];
};

export function ActivityDownload(props: ActivityDownloadProps): ReactElement {
    const [data, setData] = useState<DownloadDetails[]>([]);
    const [columns, setColumns] = useState<Columns>();
    const { accounts, accountIndex } = useAccountContext();

    useEffect(() => {
        if (props.filteredHistory.length > 0) {
            let copy = JSON.parse(JSON.stringify(props.filteredHistory)) as TransactionDetails[];

            let details = copy
                .sort((a, b) => dayjs(a.TransactionDate).diff(dayjs(b.TransactionDate)))
                .map((x) => {
                    return {
                        TransactionDate: formatTransactionDate(x.TransactionDate),
                        ReferenceNumber: x.ReferenceNumber,
                        TransactionAmount: x.TransactionAmount,
                        MerchantDescription: x.MerchantDescription
                    } as DownloadDetails;
                });

            setData(details);

            let keys = Object.keys(details[0]);
            let columns = keys.map((k) => {
                return { id: k, displayName: k };
            });
            setColumns(columns);
        }
    }, [props.filteredHistory]);

    function exportCSV(): Promise<DownloadDetails[]> {
        return Promise.resolve(data);
    }

    function exportOFX() {
        let refNumber =
            (accounts![accountIndex].isApplicationStatus
                ? accounts![accountIndex].referenceNumber?.toString()
                : accounts![accountIndex].maskedAccountNumber.slice(-4)) ?? "";

        let fileContent = OFXExporter(data, refNumber, 0, dayjs());

        const element = document.createElement("a");
        const file = new Blob([fileContent], { type: "text/ofx" });
        element.href = URL.createObjectURL(file);
        element.download = "MyPremierCreditcardDownload.ofx";
        document.body.appendChild(element);
        element.click();
    }

    return <ActivityDownloadView {...props} columns={columns} exportCSV={exportCSV} exportOFX={exportOFX} />;
}

const formatTransactionDate = (date: Date | undefined): string => {
    if (date === undefined) return "";

    return dayjs(date).format("MM/DD/YYYY");
};
