import { AxiosResponse } from "axios";
import { useContext, useEffect } from "react";
import { AppSettings } from ".";
import { UseGet, useSessionStorage } from "../../../hooks";
import { Env } from "../../../res/react-env-variables";
import { IsNullOrEmpty } from "../../../utility";
import { SettingsContext } from "./settingsContext";

export const useSettingsContext = () => {
    const context = useContext(SettingsContext);
    const client = useSettingsClient();
    const [storage, storeValue] = useSessionStorage(["appSettings"]);

    useEffect(() => {
        if (
            (IsNullOrEmpty(storage["appSettings"]) || JSON.stringify(storage["appSettings"]) === "{}") &&
            (Env === "Development" ? JSON.stringify(context.appSettings) === "{}" : true)
        ) {
            client.Get();
        } else if (JSON.stringify(context.appSettings) === "{}") {
            context.setAppSettings(JSON.parse(storage.appSettings));
        } else if (Env === "Development") {
            storeValue("appSettings", JSON.stringify(context.appSettings));
        }
    }, []);

    return {
        ...context,
        ...client,
        appSettings:
            Object.keys(context.appSettings).length !== 0
                ? context.appSettings
                : (JSON.parse(storage.appSettings ?? "{}") as AppSettings)
    };
};

const useSettingsClient = () => {
    const context = useContext(SettingsContext);
    const [, storeValue] = useSessionStorage(["appSettings"]);

    async function Get(): Promise<AxiosResponse<AppSettings, any>> {
        context.setLoading(true);
        return await UseGet<AppSettings>("api/v1/settings/web").then((x) => {
            context.setAppSettings(x.data);
            storeValue("appSettings", JSON.stringify(x.data));
            context.setLoading(false);
            return x;
        });
    }

    return { Get };
};
