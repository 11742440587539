import { AxiosError } from "axios";
import { FormikHelpers } from "formik";
import { ReactElement, useEffect } from "react";
import { ValidatePassword } from "../..";
import { ChangePasswordFields, ChangePasswordView } from "./changePasswordView";
import { useChangePasswordViewModel } from "./changePasswordViewModel";

export function ChangePassword(): ReactElement {
    const { hooks, state, api } = useChangePasswordViewModel();

    useEffect(() => {
        state.setShowNav(false);
    }, []);

    function checkPassword(password: string, passwordType: string, values: ChangePasswordFields): void {
        let newPassword = passwordType === "password" ? password : values.password;
        let confirmPassword = passwordType === "confirmPassword" ? password : values.confirmPassword;
        let verifyPassword = passwordType === "verifyPassword" ? password : values.verifyPassword;

        let confirmed = newPassword === confirmPassword && confirmPassword.length > 0;
        values.passwordCriteria = ValidatePassword(newPassword, confirmPassword, state.passwordTouched);

        values.passwordCriteria = {
            ...values.passwordCriteria,
            confirmed: confirmed
        };

        if (!state.passwordTouched && newPassword.length > 0) state.setPasswordTouched(true);
        if (!state.confirmTouched && confirmPassword.length > 0) state.setConfirmTouched(true);

        state.setCanContinue(
            newPassword.length > 0 &&
                confirmPassword.length > 0 &&
                verifyPassword.length > 0 &&
                values.passwordCriteria.hasError === false
        );
    }

    function onCancel() {
        hooks.navigate("/account/dashboard");
    }

    function onSubmit(values: ChangePasswordFields, actions: FormikHelpers<ChangePasswordFields>) {
        api.passwordChange(values.verifyPassword, values.password)
            .then((res) => {
                hooks.removeLocal("TrustedDeviceAuthentication");
                values.verifyPassword = "";
                values.password = "";
                values.confirmPassword = "";
                state.setShowAlert(true);
                state.setPasswordTouched(false);
                state.setConfirmTouched(false);
            })
            .catch((err: AxiosError<any, any>) => {
                state.setShowAlert(false);
                state.setPasswordTouched(false);
                state.setConfirmTouched(false);
                if (err.response?.data?.errors?.CurrentPassword && err.response?.data?.errors?.DesiredPassword) {
                    values.verifyPassword = "";
                    values.password = "";
                    values.confirmPassword = "";

                    actions.setErrors({
                        verifyPassword: err.response.data.errors.CurrentPassword[0],
                        password: err.response.data.errors.DesiredPassword[0],
                        confirmPassword: err.response.data.errors.DesiredPassword[0]
                    });
                } else if (err.response?.data?.errors?.CurrentPassword) {
                    values.verifyPassword = "";
                    actions.setErrors({
                        verifyPassword: err.response.data.errors.CurrentPassword[0]
                    });
                } else if (err.response?.data?.errors?.DesiredPassword) {
                    values.password = "";
                    values.confirmPassword = "";
                    actions.setErrors({
                        password: err.response.data.errors.DesiredPassword[0],
                        confirmPassword: err.response.data.errors.DesiredPassword[0]
                    });
                }
            })
            .finally(() => {
                actions.setSubmitting(false);
            });
    }

    return <ChangePasswordView {...state} checkPassword={checkPassword} onCancel={onCancel} onSubmit={onSubmit} />;
}
