import { AxiosResponse } from "axios";
import {
    AboutUsCms,
    AccountActivityCms,
    AccountSummaryCms,
    AccountTransactionHistoryCms,
    AdditionalCardCms,
    ApplicationStatusCms,
    BrowserMessageCmsModel,
    BrowserVersionCmsModel,
    CCPALandingPageModel,
    ChangePasswordCms,
    CmsToolTip,
    CommunicationSettingsCmsModel,
    ConsumerAlertCms,
    ContactUsCms,
    CreditLineIncreaseCms,
    CurrentCreditCardOffersCms,
    DisclosuresCms,
    DisputeTransactionCms,
    EnrollmentCmsModel,
    FaqCmsModel,
    FormsAndMaterialsCms,
    GlobalImageDataCms,
    HelpCmsModel,
    InquiryCms,
    IntermediateWarningCms,
    LettersCms,
    LinkYourAccountsCms,
    LoginCms,
    MultipleAccountPopupCms,
    PaymentCms,
    PremierCreditProtectionCMS,
    PrivateCCPAPageModel,
    PublicCCPAPageViewModel,
    RequestPinCms,
    StatementsCms,
    SystemOptionsCms,
    ToolTipCode,
    ToolTipsCms,
    UpdateProfileCms
} from ".";
import { UseGet } from "../../../hooks";

//CANNOT use Contexts in a Route Loader
//Since there isnt a Context here
//This can Be used in Route Loader

export const useCms = () => {
    async function AboutUs(): Promise<AxiosResponse<AboutUsCms, any>> {
        return await UseGet("/api/v1/cms/AboutUsPage");
    }

    async function AccountActivity(): Promise<AxiosResponse<AccountActivityCms, any>> {
        return await UseGet(`/api/v1/cms/AccountActivityPage`);
    }

    async function AccountSummary(): Promise<AxiosResponse<AccountSummaryCms, any>> {
        return await UseGet(`/api/v1/cms/AccountSummaryMobilePage`);
    }

    async function AccountTransactionHistory(): Promise<AxiosResponse<AccountTransactionHistoryCms, any>> {
        return await UseGet(`/api/v1/cms/AccountTransactionHistoryPage`);
    }

    async function AdditionalCard(): Promise<AxiosResponse<AdditionalCardCms, any>> {
        return await UseGet(`/api/v1/cms/AdditionalCardMobilePage`);
    }

    async function ApplicationStatus(): Promise<AxiosResponse<ApplicationStatusCms, any>> {
        return await UseGet(`/api/v1/cms/ApplicationStatusPage`);
    }

    async function BrowserMessage(): Promise<AxiosResponse<BrowserMessageCmsModel, any>> {
        return await UseGet(`/api/v1/cms/BrowserMessagePage`);
    }

    async function BrowserVersion(): Promise<AxiosResponse<BrowserVersionCmsModel, any>> {
        return await UseGet(`/api/v1/cms/GlobalBrowserVersionConfigurationPage`);
    }

    async function CCPALandingPage(): Promise<AxiosResponse<CCPALandingPageModel, any>> {
        return await UseGet(`/api/v1/cms/CCPALandingPage`);
    }

    async function CCPAPrivatePage(): Promise<AxiosResponse<PrivateCCPAPageModel, any>> {
        return await UseGet(`/api/v1/cms/PrivateCCPAPage`);
    }

    async function CCPAPublicPage(): Promise<AxiosResponse<PublicCCPAPageViewModel, any>> {
        return await UseGet(`/api/v1/cms/PublicCCPAPage`);
    }

    async function ChangePassword(): Promise<AxiosResponse<ChangePasswordCms, any>> {
        return await UseGet(`/api/v1/cms/ChangePasswordPage`);
    }

    async function CommunicationSettings(): Promise<AxiosResponse<CommunicationSettingsCmsModel, any>> {
        return await UseGet(`/api/v1/cms/CommunicationSettingsPage`);
    }

    async function ConsumerAlert(): Promise<AxiosResponse<ConsumerAlertCms, any>> {
        return await UseGet(`/api/v1/cms/ConsumerAlertPage`);
    }

    async function ContactUs(): Promise<AxiosResponse<ContactUsCms, any>> {
        return await UseGet(`/api/v1/cms/ContactUsPage`);
    }

    async function CreditLineIncrease(): Promise<AxiosResponse<CreditLineIncreaseCms, any>> {
        return await UseGet(`/api/v1/cms/CreditLineIncreasePage`);
    }

    async function CurrentCreditCardOffers(): Promise<AxiosResponse<CurrentCreditCardOffersCms, any>> {
        return await UseGet(`/api/v1/cms/CurrentCreditCardOffersPage`);
    }

    async function Disclosures(): Promise<AxiosResponse<DisclosuresCms, any>> {
        return await UseGet(`/api/v1/cms/DisclosurePage`);
    }

    async function DisputeTransaction(): Promise<AxiosResponse<DisputeTransactionCms, any>> {
        return await UseGet(`/api/v1/cms/DisputeTransactionMobilePage`);
    }

    async function Enrollment(): Promise<AxiosResponse<EnrollmentCmsModel, any>> {
        return await UseGet(`/api/v1/cms/EnrollmentPage`);
    }

    async function Faq(): Promise<AxiosResponse<FaqCmsModel, any>> {
        return await UseGet(`/api/v1/cms/FaqPage`);
    }

    async function FormsAndMaterials(): Promise<AxiosResponse<FormsAndMaterialsCms, any>> {
        return await UseGet(`/api/v1/cms/GlobalCollateralFormsAndMaterialsConfigurationPage`);
    }

    async function GlobalImageData(): Promise<AxiosResponse<GlobalImageDataCms, any>> {
        return await UseGet(`/api/v1/cms/GlobalImageDataConfigurationPage`);
    }

    async function Help(): Promise<AxiosResponse<HelpCmsModel, any>> {
        return await UseGet(`/api/v1/cms/HelpPage`);
    }

    async function Inquiry(): Promise<AxiosResponse<InquiryCms, any>> {
        return await UseGet(`/api/v1/cms/InquiryMobilePage`);
    }

    async function IntermediateWarning(): Promise<AxiosResponse<IntermediateWarningCms, any>> {
        return await UseGet(`/api/v1/cms/GlobalCollateralIntermediateWarningConfigurationPage`);
    }

    async function Letters(): Promise<AxiosResponse<LettersCms, any>> {
        return await UseGet(`/api/v1/cms/LettersPage`);
    }

    async function LinkYourAccounts(): Promise<AxiosResponse<LinkYourAccountsCms, any>> {
        return await UseGet(`/api/v1/cms/LinkYourAccountsPage`);
    }

    async function Login(): Promise<AxiosResponse<LoginCms, any>> {
        return await UseGet(`/api/v1/cms/LoginPage`);
    }

    async function MultipleAccountPopup(): Promise<AxiosResponse<MultipleAccountPopupCms, any>> {
        return await UseGet(`/api/v1/cms/GlobalCollateralMultipleAccountPopupConfigurationPage`);
    }

    async function Payment(): Promise<AxiosResponse<PaymentCms, any>> {
        return await UseGet(`/api/v1/cms/PaymentMobilePage`);
    }

    async function PremierCreditProtection(): Promise<AxiosResponse<PremierCreditProtectionCMS, any>> {
        return await UseGet(`/api/v1/cms/PremierCreditProtectionPage`);
    }

    async function RequestPin(): Promise<AxiosResponse<RequestPinCms, any>> {
        return await UseGet(`/api/v1/cms/RequestPinMobilePage`);
    }

    async function Statements(): Promise<AxiosResponse<StatementsCms, any>> {
        return await UseGet(`/api/v1/cms/StatementMobilePage`);
    }

    async function SystemOptions(): Promise<AxiosResponse<SystemOptionsCms, any>> {
        return await UseGet(`/api/v1/cms/GlobalCollateralSystemOptionsConfigurationPage`);
    }

    async function UpdateProfile(): Promise<AxiosResponse<UpdateProfileCms, any>> {
        return await UseGet(`/api/v1/cms/UpdateProfilePage`);
    }

    async function ToolTips(): Promise<AxiosResponse<ToolTipsCms, any>> {
        return await UseGet(`/api/v1/cms/GlobalCollateralToolTipPage`);
    }

    function EmptyTip(code: ToolTipCode): CmsToolTip {
        return {
            Id: 0,
            BodyText: "",
            Active: false,
            Name: "",
            ToolTipCode: code,
            ToolTipType: ""
        };
    }

    return {
        AboutUs,
        AccountActivity,
        AccountSummary,
        AccountTransactionHistory,
        AdditionalCard,
        ApplicationStatus,
        BrowserMessage,
        BrowserVersion,
        CCPALandingPage,
        CCPAPrivatePage,
        CCPAPublicPage,
        ChangePassword,
        CommunicationSettings,
        ConsumerAlert,
        ContactUs,
        CreditLineIncrease,
        CurrentCreditCardOffers,
        Disclosures,
        DisputeTransaction,
        Enrollment,
        Faq,
        FormsAndMaterials,
        GlobalImageData,
        Help,
        Inquiry,
        IntermediateWarning,
        Letters,
        LinkYourAccounts,
        Login,
        MultipleAccountPopup,
        Payment,
        PremierCreditProtection,
        RequestPin,
        Statements,
        SystemOptions,
        ToolTips,
        EmptyTip,
        UpdateProfile
    };
};
