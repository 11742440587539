import { ReactElement, useContext, useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { ApplicationStatusCms, UserAccount, useAccountContext, useCms } from "../../../api/backend";
import { CardNavContext, useSwrveContext } from "../../../contexts";
import { useSessionStorage, useViewport } from "../../../hooks";
import { Size } from "../../../types";

type DashboardViewModel = {
    hooks: DashboardHooks;
    state: DashboardState;
};

type DashboardHooks = {
    navigate: NavigateFunction;
    removeValue: (names: string[]) => void;
    insertSwrveEvent: (event: string) => void;
};

export type DashboardState = {
    size: Size;
    accounts: UserAccount[] | undefined;
    rows: ReactElement[];
    setRows: React.Dispatch<React.SetStateAction<ReactElement[]>>;
    setShowNav: React.Dispatch<React.SetStateAction<boolean>>;
    appCms: ApplicationStatusCms | undefined;
};

export const storageKeys = ["username", "password", "mfaOptions"];

export function useDashboardViewModel(): DashboardViewModel {
    const navigate = useNavigate();
    const { size } = useViewport(true);
    const { accounts } = useAccountContext();
    const { ApplicationStatus } = useCms();
    const [rows, setRows] = useState([] as ReactElement[]);
    const [, , removeValue] = useSessionStorage(storageKeys);
    const { insertSwrveEvent } = useSwrveContext();
    const { setShowNav } = useContext(CardNavContext);
    const [appCms, setAppCms] = useState<ApplicationStatusCms>();

    useEffect(() => {
        ApplicationStatus().then((res) => {
            setAppCms(res.data);
        });
    }, []);

    return {
        hooks: { navigate, removeValue, insertSwrveEvent },
        state: { size, accounts, rows, setRows, setShowNav, appCms }
    };
}
