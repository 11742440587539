import { ReactElement, useEffect } from "react";
import BrowserCompatibilityView from "./browserCompatibilityView";
import { useBrowserCompatibilityViewModel } from "./browserCompatibilityViewModel";

export function BrowserCompatibilityModel(): ReactElement {
    const { hooks, state } = useBrowserCompatibilityViewModel();

    useEffect(() => {
        if (state.acknowledgement === true) onContinue();
    }, []);

    useEffect(() => {
        if (state.supported && state.current) onContinue();
    }, [state.supported, state.current]);

    function onContinue() {
        state.setAcknowledgement(true);
        hooks.storeValue("browserAcknowledgement", true);
        if (state.access_token) hooks.navigate("/account/dashboard");
        else hooks.navigate("/auth/login");
    }

    return <BrowserCompatibilityView {...state} onContinue={onContinue} />;
}
