import { Form, Formik } from "formik";
import { ReactElement } from "react";
import { InputField, Loading } from "../..";
import { FormProps } from "../../../types";
import { Alert, BackArrow, ButtonPro, GenericModal, ToolTip } from "../../generic";
import "./manageAlerts.scss";
import { ManageAlertsTermsModel } from "./manageAlertsTerms/manageAlertsTermsModel";
import { ManageAlertsTextsModel } from "./manageAlertsTexts/manageAlertsTextsModel";
import { ManageAlertsFormFields, ManageAlertsState } from "./manageAlertsViewModel";

type ManageAlertsViewProps = ManageAlertsState & {
    onChange: Function;
    onBlur: Function;
    onCancelUpdatedPhoneNumber: Function;
    onSaveUpdatedPhoneNumber: Function;
    onSubmit: Function;
    onEditAlertPhone: Function;
    onDeclineTerms: Function;
    onToggle: Function;
    onAccept: Function;
    onEditSpecificAlerts: Function;
    onCancelEditSpecificAlerts: Function;
    onSaveSpecificAlerts: Function;
    onCloseViewTerms: Function;
    onViewTerms: Function;
};

export function ManageAlertsView(props: ManageAlertsViewProps): ReactElement {
    const initialValues = {
        confirmMobilePhoneNumber: props.enrolledMobileNumberFormatted,
        mobilePhoneNumber: props.enrolledMobileNumberFormatted
    } as ManageAlertsFormFields;
    const alertText: string =
        "By giving us a cell number or a number later converted to a cell, you consent to receive text messages that may be sent by an automated dialing system.<br /><br />PREMIER Bankcard cannot guarantee the delivery of alerts during service outages.<br /><br />You will receive the text alert at the time the selected action occurs, which may include during overnight hours.";

    return (
        <>
            <div id={`manageAlerts${props.size}`}>
                <BackArrow available to={`/account/summary/${props.accountIndex}/home`}>
                    <h2 className="manageAlertsHeader">Alerts</h2>
                </BackArrow>

                <div className="manageAlertsForm">
                    <div className="manageAlertsForm-header">
                        <div className="manageAlerts-section-header-content">
                            <div className="manageAlerts-section-header">Text Message Alerts</div>
                            <div className="manageAlertsForm-header-text">
                                <label className={`togglePro${props.toggleDisabled ? "-disabled" : ""}`}>
                                    <input
                                        title="Alerts Toggle"
                                        id="toggleAlerts"
                                        type="checkbox"
                                        checked={props.isEnrolled || props.isEditing}
                                        onChange={(e) => props.onToggle(e)}
                                        disabled={props.toggleDisabled}
                                    />
                                    <span id="" className="togglePro-switch"></span>
                                </label>
                            </div>
                        </div>
                        <div className="manageAlertsForm-content">
                            {props.isEditing ? (
                                <>
                                    <div className="manageAlertsForm-form">
                                        <Formik
                                            enableReinitialize
                                            initialValues={initialValues}
                                            onSubmit={(values, actions) => {
                                                props.onSubmit(values, actions);
                                            }}
                                        >
                                            {(formProps: FormProps<ManageAlertsFormFields>) => (
                                                <Form className={`brandedCard-form${props.size}`} autoComplete="off">
                                                    <div className="manageAlertsForm-inputPair">
                                                        <InputField
                                                            id="mobilePhoneNumber"
                                                            label="Mobile Phone Number"
                                                            placeholder="Enter a mobile phone number"
                                                            mask="(999) 999-9999"
                                                            inputMode="numeric"
                                                            error={formProps.errors.mobilePhoneNumber}
                                                            onChange={props.onChange(formProps)}
                                                            onBlur={props.onBlur(formProps)}
                                                            ignoreTouched={true}
                                                            alwaysHighlightLine={true}
                                                            showErrorMessage={false}
                                                            highLightLineClass={
                                                                formProps.errors.mobilePhoneNumber
                                                                    ? "inputField-row-error"
                                                                    : ""
                                                            }
                                                        ></InputField>
                                                    </div>

                                                    <div className="manageAlertsForm-inputPair">
                                                        <InputField
                                                            id="confirmMobilePhoneNumber"
                                                            label="Confirm Mobile Phone Number"
                                                            placeholder="Confirm mobile phone number"
                                                            mask="(999) 999-9999"
                                                            inputMode="numeric"
                                                            error={formProps.errors.confirmMobilePhoneNumber}
                                                            onChange={props.onChange(formProps)}
                                                            onBlur={props.onBlur(formProps)}
                                                            ignoreTouched={true}
                                                            alwaysHighlightLine={true}
                                                            showErrorMessage={true}
                                                            highLightLineClass={
                                                                formProps.errors.confirmMobilePhoneNumber
                                                                    ? "inputField-row-error"
                                                                    : ""
                                                            }
                                                        ></InputField>
                                                    </div>

                                                    {props.canSubmit ? (
                                                        <div className="manageAlertsForm-positive">
                                                            Phone numbers match
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    <div className="manageAlertsForm-form">
                                                        <div className="alertpage-alert">
                                                            <Alert level="info">
                                                                <span
                                                                    className="manageAlertsForm-alert-text"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: alertText
                                                                    }}
                                                                />
                                                            </Alert>
                                                        </div>
                                                    </div>

                                                    <div className="manageAlertsForm-button-section">
                                                        {props.isEnrolled && (
                                                            <>
                                                                <div className={`brandedCard-buttons${props.size}`}>
                                                                    <ButtonPro
                                                                        type="button"
                                                                        variant="neutral"
                                                                        onClick={() =>
                                                                            props.onCancelUpdatedPhoneNumber()
                                                                        }
                                                                    >
                                                                        Cancel
                                                                    </ButtonPro>
                                                                </div>
                                                                <div className={`brandedCard-buttons${props.size}`}>
                                                                    <ButtonPro
                                                                        type="button"
                                                                        disabled={!props.canSubmit}
                                                                        onClick={() =>
                                                                            props.onSaveUpdatedPhoneNumber(formProps)
                                                                        }
                                                                    >
                                                                        Save
                                                                    </ButtonPro>
                                                                </div>
                                                            </>
                                                        )}
                                                        {!props.isEnrolled && (
                                                            <div className={`brandedCard-buttons${props.size}`}>
                                                                <ButtonPro
                                                                    type="submit"
                                                                    disabled={!props.canSubmit}
                                                                    onClick={() => props.onSubmit(formProps)}
                                                                >
                                                                    Next
                                                                </ButtonPro>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <Loading show={formProps.isSubmitting} />
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {props.isEnrolled ? (
                                        <>
                                            <div className="manageAlertsForm-form">
                                                <div className="manageAlertsForm-form-mobile-number-container">
                                                    <div className="manageAlertsForm-form-mobile-number-label">
                                                        Mobile Phone Number
                                                    </div>
                                                    <ToolTip>
                                                        <span
                                                            id="manageAlertsForm-alert-text"
                                                            className="manageAlertsForm-alert-text"
                                                            dangerouslySetInnerHTML={{
                                                                __html: alertText
                                                            }}
                                                        />
                                                    </ToolTip>
                                                </div>
                                                <div className="manageAlertsForm-form-mobile-number">
                                                    {props.enrolledMobileNumberFormatted}
                                                </div>
                                                <div className="manageAlerts-edit-label">
                                                    <ButtonPro
                                                        variant="tertiary"
                                                        className="manageAlerts-edit-phone-number"
                                                        onClick={() => props.onEditAlertPhone()}
                                                        disabled={props.isEditingSpecificAlerts}
                                                    >
                                                        <p className="manageAlerts-edit-label-text">
                                                            Edit Phone Number
                                                        </p>
                                                    </ButtonPro>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="manageAlertsForm-form">
                                            <div className="alertpage-alert">
                                                <Alert level="info">
                                                    <span
                                                        className="manageAlertsForm-alert-text"
                                                        dangerouslySetInnerHTML={{
                                                            __html: alertText
                                                        }}
                                                    />
                                                </Alert>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        {props.isEnrolled && props.optInText === "pending" && (
                            <div className="manage-alerts-edit-alert-alerts-section">
                                <div className="alertpage-alert">
                                    <Alert level="warning">
                                        To begin receiving alerts, respond 'Y' to the text message we sent you from
                                        62465. Choose your preferences below.
                                    </Alert>
                                </div>
                            </div>
                        )}

                        {props.isEnrolled && props.optInText === "false" && (
                            <div className="manage-alerts-edit-alert-alerts-section">
                                <div className="alertpage-alert">
                                    <Alert level="info">
                                        You are enrolled in Alerts but have paused text notifications. To resume, reply
                                        'START' to 62465.
                                    </Alert>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {props.showTermsModal && (
                    <ManageAlertsTermsModel
                        onDeclineTerms={props.onDeclineTerms}
                        onAccept={props.onAccept}
                        onCloseViewTerms={props.onCloseViewTerms}
                        viewOnlyTermsAndConditions={props.viewOnlyTermsAndConditions}
                    />
                )}

                <GenericModal show={props.showModalEnrollPhoneSuccess} {...props.modalEnrollPhoneSuccessProps} />
                <GenericModal show={props.showModalUpdatePhoneSuccess} {...props.modalUpdatePhoneSuccessProps} />
                <GenericModal show={props.showModalTurnOffAlertsQuestion} {...props.modalTurnOffAlertsQuestionProps} />

                <ManageAlertsTextsModel
                    onEditingTextAlerts={props.onEditSpecificAlerts}
                    onSaveTextAlerts={props.onSaveSpecificAlerts}
                    onCancelEditTextAlerts={props.onCancelEditSpecificAlerts}
                    isEditingSpecificAlertsChanged={props.isEditingSpecificAlertsChanged}
                    setIsEditingSpecificAlertsChanged={props.setIsEditingSpecificAlertsChanged}
                    originalAlerts={props.originalAlerts}
                    isEnrolled={props.isEnrolled}
                    isLoading={props.isLoading}
                    setIsLoading={props.setIsLoading}
                    isEditing={props.isEditing}
                    isEditingSpecificAlerts={props.isEditingSpecificAlerts}
                    setIsEditingSpecificAlerts={props.setIsEditingSpecificAlerts}
                    canDiscardSpecificAlertsChanges={props.canDiscardSpecificAlertsChanges}
                    setCanDiscardSpecificAlertsChanges={props.setCanDiscardSpecificAlertsChanges}
                />

                <div className="manageAlertsForm-terms-link-div">
                    <span
                        className="manageAlertsForm-terms-link"
                        onClick={() => {
                            props.onViewTerms(true);
                        }}
                    >
                        Alerts Terms and Conditions
                    </span>
                </div>
            </div>
            <GenericModal show={props.blocker.state === "blocked"} {...props.discardChangesModal} />
            <Loading show={props.isLoading} />
        </>
    );
}
