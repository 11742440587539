import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccountContext, useDeliveryPreferencesContext, useSettingsContext } from "../../../api/backend";
import { Alert, AsyncElement } from "../../generic";
import { ActivitySummaryState } from "../summary/activitySummaryViewModel";

export function EnrollInPaperlessAlert(props: ActivitySummaryState): React.ReactElement {
    const navigate = useNavigate();
    const { accountIndex } = useAccountContext();
    const { appSettings } = useSettingsContext();
    const isEMessengerDown: boolean = appSettings?.featureFlags?.isEMessengerDown;
    const { loading, preferences } = useDeliveryPreferencesContext();
    const [showPaperlessAlert, setShowPaperlessAlert] = useState(false);

    useEffect(() => {
        try {
            var sp =
                !isEMessengerDown &&
                preferences !== undefined &&
                (preferences.find((x) => x.accountHandle === props.accounts![props.accountIndex].accountHandle)
                    ?.eStatementsEnrollment?.changesAllowed ??
                    false) &&
                (preferences.find((x) => x.accountHandle === props.accounts![props.accountIndex].accountHandle)
                    ?.eStatementsEnrollment?.enrollmentStatus === "NotEnrolled" ??
                    false);
            setShowPaperlessAlert(sp);
        } catch (error) {
            console.log(error);
        }
    }, [preferences, accountIndex]);

    function EnrollInPaperless() {
        navigate("/profile/delivery-options");
    }

    return (
        <>
            <AsyncElement loading={loading}>
                {showPaperlessAlert ? (
                    <>
                        <Alert
                            level={"warning"}
                            text={"We see you're not enrolled in paperless."}
                            ctaText="Enroll here"
                            more={EnrollInPaperless}
                        />
                    </>
                ) : (
                    <></>
                )}
            </AsyncElement>
        </>
    );
}
