import { ReactElement } from "react";
import { Activity, Statements } from "../..";
import { BackArrow } from "../../generic";
import { EnrollInPaperlessAlert } from "../enrollinPaperlessAlert/enrollinPaperlessAlert";
import "./activitySummary.scss";
import ActivitySummarySplash from "./activitySummarySplash/activitySummarySplash";
import { ActivitySummaryState } from "./activitySummaryViewModel";

export function ActivitySummaryView(props: ActivitySummaryState): ReactElement {
    return (
        <>
            <div id={`activitySummary${props.size}`}>
                <BackArrow available to={`/account/summary/${props.accountIndex}/home`}>
                    <ActivitySummarySplash {...props} />
                </BackArrow>

                <Activity account={props.accounts[props.accountIndex]} index={props.accountIndex} />
                {!props.isEMessengerDown && props.allowPaperless ? <EnrollInPaperlessAlert {...props} /> : <></>}
                <Statements account={props.accounts[props.accountIndex]} index={props.accountIndex} />
            </div>
        </>
    );
}
