import { ReactElement, createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSettingsContext } from "../api/backend";
import { useSessionStorage } from "../hooks";
import { Children } from "../types";
import { NotImplemented } from "../utility";

type OneTrustContextProps = {
    consumerGuid: string;
    setConsumerGuid: React.Dispatch<React.SetStateAction<string>>;
    jwt: string;
    setJwt: React.Dispatch<React.SetStateAction<string>>;
};

export const OneTrustContext = createContext<OneTrustContextProps>({
    consumerGuid: "",
    setConsumerGuid: NotImplemented,
    jwt: "",
    setJwt: NotImplemented
});

const useOneTrustContextProps = (): OneTrustContextProps => {
    const [consumerGuid, setConsumerGuid] = useState("");
    const [jwt, setJwt] = useState("");

    return { consumerGuid, setConsumerGuid, jwt, setJwt };
};

export function OneTrust(props: Children): ReactElement {
    const contextProps = useOneTrustContextProps();
    const [storage] = useSessionStorage(["consumerGuid", "jwt"]);
    const win = window as any;
    const [, , removeCookie] = useCookies(["ADRUM", "ADRUM_BT1", "ADRUM_BTa"]);

    useEffect(() => {
        if (
            storage["consumerGuid"] === "undefined" ||
            storage["consumerGuid"] === undefined ||
            storage["consumerGuid"] === ""
        ) {
            //not set
        } else {
            contextProps.setConsumerGuid(storage["consumerGuid"]);
        }

        if (storage["jwt"] === "undefined" || storage["jwt"] === undefined || storage["jwt"] === "") {
            //not set
        } else {
            contextProps.setJwt(storage["jwt"]);
        }

        setTimeout(() => {
            if (
                win.OnetrustActiveGroups !== "" &&
                win.OnetrustActiveGroups !== undefined &&
                !win.OnetrustActiveGroups.includes("C0002")
            ) {
                const cookiesToDelete = ["ADRUM", "ADRUM_BT1", "ADRUM_BTa"];

                cookiesToDelete.forEach((cookieName) => {
                    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; Secure; SameSite=Lax;`;
                });

                DeleteAdrumCookies();
            }
        }, 2000);
    }, []);

    useEffect(() => {
        if (
            win.OnetrustActiveGroups !== "" &&
            win.OnetrustActiveGroups !== undefined &&
            !win.OnetrustActiveGroups.includes("C0002")
        ) {
            DeleteAdrumCookies();
        }
    }, [win.OnetrustActiveGroups]);

    function DeleteAdrumCookies() {
        removeCookie("ADRUM", { path: "/" });
        removeCookie("ADRUM", { path: "/", domain: ".pbc-np.com" });
        removeCookie("ADRUM", { path: "/", domain: ".mypremiercreditcard.com" });
        removeCookie("ADRUM", { path: "/", domain: ".appdynamics.com" });

        removeCookie("ADRUM_BT1", { path: "/", domain: "col.eum-appdynamics.com" });
        removeCookie("ADRUM_BTa", { path: "/", domain: "col.eum-appdynamics.com" });
    }

    return (
        <>
            <OneTrustContext.Provider value={contextProps}>{props.children}</OneTrustContext.Provider>
        </>
    );
}

export const useOneTrustContext = () => {
    const context = useContext(OneTrustContext);
    const [, storeValue, removeValue] = useSessionStorage(["consumerGuid", "jwt", "OneTrustSync"]);
    const win = window as any;
    const { appSettings } = useSettingsContext();
    const oneTrustEnabled: boolean = appSettings?.featureFlags?.oneTrustEnabled;

    function Sync(consumerGuid: string, jwt: string) {
        if (oneTrustEnabled && win?.OneTrust?.syncConsentProfile && win?.OneTrust?.syncConsentProfile !== undefined) {
            storeValue("OneTrustSync", "true");
            win.OneTrust.syncConsentProfile(consumerGuid, jwt, false);
        }
    }

    function UpdateConsumerGuid(consumerGuid?: string) {
        if (consumerGuid) {
            context.setConsumerGuid(consumerGuid);
            storeValue("consumerGuid", consumerGuid);
        }
    }

    function ClearConsumerGuid() {
        context.setConsumerGuid("");
        removeValue(["consumerGuid"]);
    }

    function UpdateJwt(jwt?: string) {
        if (jwt) {
            context.setJwt(jwt);
            storeValue("jwt", jwt);
        }
    }

    function ClearJwt() {
        context.setJwt("");
        removeValue(["jwt"]);
    }

    return {
        syncOneTrustConsentProfile: Sync,
        updateConsumerGuid: UpdateConsumerGuid,
        clearConsumerGuid: ClearConsumerGuid,
        updateJwt: UpdateJwt,
        clearJwt: ClearJwt
    };
};
