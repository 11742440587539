import { useState } from "react";
import { useViewport } from "../../../hooks";
import { Size } from "../../../types";

type LoadingViewModel = {
    state: LoadingState;
};

export type LoadingState = {
    size: Size;
    showDelayMsg: boolean;
    setShowDelayMsg: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useLoadingViewModel(): LoadingViewModel {
    const { size } = useViewport(false, true);
    const [showDelayMsg, setShowDelayMsg] = useState(false);

    return {
        state: { size, showDelayMsg, setShowDelayMsg }
    };
}
