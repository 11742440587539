import { ReactElement } from "react";
import { UserAccount } from "../../../../api/backend";
import { Size } from "../../../../types";
import "./activitySummarySplash.scss";

type ActivitySummarySplashProps = {
    size: Size;
    accounts: UserAccount[];
    accountIndex: number;
};

function ActivitySummarySplash(props: ActivitySummarySplashProps): ReactElement {
    return (
        <div id={`activitySummarySplash${props.size}`}>
            <h2 className="activitySummarySplash-content">
                Your&nbsp;
                <span className={`activitySummarySplash-indexed-${props.accounts[props.accountIndex].accent}`}>
                    Activity & Statements
                </span>
            </h2>
        </div>
    );
}

export default ActivitySummarySplash;
