import { AxiosResponse } from "axios";
import { AdditionalCardGetResponse, AdditionalCardPostRequest, AdditionalCardPostResponse } from ".";
import { UseGet, UsePost } from "../../../hooks";
import { useIdentityConnectContext } from "../../identityServer";

export const useAdditionalCardContext = () => {
    const client = useAdditionalCardClient();

    return { ...client };
};

const useAdditionalCardClient = () => {
    const { access_token } = useIdentityConnectContext();

    async function GetCms(accountHandle: string): Promise<AxiosResponse<AdditionalCardGetResponse, any>> {
        return await UseGet(`api/v1/additionalcard?accountHandle=${accountHandle}`, access_token);
    }

    async function Post(
        accountHandle: string,
        request: AdditionalCardPostRequest
    ): Promise<AxiosResponse<AdditionalCardPostResponse, any>> {
        return await UsePost(`api/v1/additionalcard?accountHandle=${accountHandle}`, access_token, request);
    }

    return {
        GetCms,
        Post
    };
};
