import { DisclosuresCms, useCms } from "../../../api/backend";

export type ManageAlertsTermsData = {
    cms: DisclosuresCms;
};

export async function ManageAlertsLoader(): Promise<ManageAlertsTermsData> {
    const { Disclosures } = useCms();
    let cms = (await Disclosures()).data;

    return { cms };
}
