import { ReactElement } from "react";
import "./loading.scss";
import { LoadingIndicator } from "./loadingIndicator";
import { LoadingState } from "./loadingViewModel";

function LoadingView(props: LoadingState): ReactElement {
    return (
        <div id="loading">
            <div id="loading-content">
                <LoadingIndicator key={props.showDelayMsg.toString()} />
                {props.showDelayMsg ? <h5>There appears to be a delay, please bear with us...</h5> : <></>}
            </div>
        </div>
    );
}

export default LoadingView;
